export const environment = {
  production: true,
  firebase :{
    apiKey: "AIzaSyAQz2Em-z46x7u_uVYObx82-8j62aoWL58",
    authDomain: "gogodoc-greece.firebaseapp.com",
    databaseURL: "https://gogodoc-greece.firebaseio.com",
    projectId: "gogodoc-greece",
    storageBucket: "gogodoc-greece.appspot.com",
    messagingSenderId: "26338344887",
    appId: "1:26338344887:web:1f56bedee4d835bc6f7232",
    measurementId: "G-4R0D92TF6Q"
    
  }
};


