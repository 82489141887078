import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { environment } from '../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})


export class APIService {


    API_URL = 'https://stagingapi.crossbordertelehealth.app';
    API_URL2 = 'https://stagingapi.crossbordertelehealth.app';
    API_URL3 = 'https://stagingapi.crossbordertelehealth.app';

    // API_URL = 'https://api.cbth.archnix.dev';
    // API_URL2 = 'https://api.cbth.archnix.dev';
    // API_URL3 = 'https://api.cbth.archnix.dev';

    // API_URL = 'https://liveapi.crossbordertelehealth.app';
    // API_URL2 = 'https://liveapi.crossbordertelehealth.app';
    // API_URL3 = 'https://liveapi.crossbordertelehealth.app';


    // API_URL = 'http://127.0.0.1:8000';
    // API_URL2 = 'http://127.0.0.1:8000';
    // API_URL3 = 'http://127.0.0.1:8000';

    app: firebase.app.App;
    db: firebase.database.Database;
    list: any[] = [];

    constructor(private httpClient: HttpClient) {
        //  this.app = firebase.initializeApp(environment.firebase);
        this.db = firebase.database();


    }

    changeDetection() {
        var gateWayRef = firebase.database().ref("doctors");
        gateWayRef.on('child_changed', function (data) {
            console.log("CHILD_CHANGE");
            console.log(data.val());
            var datos = data.val();
            console.log(datos);
            $("#refresh").load(" #refresh > *");
            this.getDoctors();
        });
    }

    /**
     * signups
     * @param days
     * @param from
     * @param to
     * @param type
     */
    getSignups(days, from, to, type) {
        if (from == undefined && type == '') {
            return this.httpClient.get(`${this.API_URL2}/api/analytics?date_range_type=` + days, {
                headers: {
                    'Content-Type': 'application/json',
                    'User-Authorization-Token': localStorage.getItem('authToken')
                }
            })
        } else if (from == undefined && type !== '') {
            return this.httpClient.get(`${this.API_URL2}/api/analytics?bookingType=` + type + `&date_range_type=` + days, {
                headers: {
                    'Content-Type': 'application/json',
                    'User-Authorization-Token': localStorage.getItem('authToken')
                }
            })
        } else if (from !== undefined && type == '') {
            return this.httpClient.get(`${this.API_URL2}/api/analytics?start_date=` + from + `&end_date=` + to + `&date_range_type=` + days, {
                headers: {
                    'Content-Type': 'application/json',
                    'User-Authorization-Token': localStorage.getItem('authToken')
                }
            })
        } else {
            return this.httpClient.get(`${this.API_URL2}/api/analytics?start_date=` + from + `&end_date=` + to + `&bookingType=` + type + `&date_range_type=` + days, {
                headers: {
                    'Content-Type': 'application/json',
                    'User-Authorization-Token': localStorage.getItem('authToken')
                }
            })
        }
    }

    getSymptoms(a, b) {
        return this.httpClient.get(`${this.API_URL2}/api/analytics/basic/top_ten_symptoms?` + "start_date=" + a + "&end_date=" + b, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }



//need
    deletePatient(id, remarks) {
        return this.httpClient.delete(`${this.API_URL}/v1/patient/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getPatientInfo(id) {
        return this.httpClient.get(`${this.API_URL2}/api/patient/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    deleteDoctor(id, remarks) {
        return this.httpClient.delete(`${this.API_URL}/v1/doc/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    addImage(jsonData) {
        return this.httpClient.post(`${this.API_URL}/api/users/update_profile_image`, jsonData, {headers: {'User-Authorization-Token': localStorage.getItem('authToken')}});
    }

//need
    addDoctor(jsonData) {
        return this.httpClient.post(`${this.API_URL}/v1/doc`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    editDoctor(id, jsonData) {
        return this.httpClient.put(`${this.API_URL}/api/doctors/edit/` + id, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    editPatient(id, jsonData) {
        return this.httpClient.put(`${this.API_URL}/v1/patient/` + id, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    updateDoctorStatus(id, status, docUID) {
        var date = new Date();
        var datetime = date.getUTCFullYear() + '-' +
            ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
            ('00' + date.getUTCDate()).slice(-2);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var sec = date.getSeconds();
        var hours2 = this.addZ(hours);
        var minutes2 = this.addZ(minutes);
        var sec2 = this.addZ(sec);
        console.log(hours2 + ":" + minutes2 + ":" + sec2);
        console.log(status);
        return this.httpClient.put(`${this.API_URL}/v1/doc/` + id + '/doc_availability/' + (status ? 1 : 0) + '/' + docUID + '/' + '?date_time=2018-09-22 00:00:00', {}, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    updateDoctorCallStatus(id, status, docUID) {
        var date = new Date();
        var datetime = date.getUTCFullYear() + '-' +
            ('00' + (date.getUTCMonth() + 1)).slice(-2) + '-' +
            ('00' + date.getUTCDate()).slice(-2);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var sec = date.getSeconds();
        var hours2 = this.addZ(hours);
        var minutes2 = this.addZ(minutes);
        var sec2 = this.addZ(sec);


        console.log(hours2 + ":" + minutes2 + ":" + sec2);
        console.log(status);
        return this.httpClient.put(`${this.API_URL}/v1/doc/` + id + '/call_availability_status/' + (status ? 1 : 0) + '/' + docUID + '/' + '?date_time=2018-09-22 00:00:00', {}, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getDoctorInfo(id, language) {
        return this.httpClient.get(`${this.API_URL2}/api/doctors/listings/get/` + id + `?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }


    getProviderInfo(id, language) {
        return this.httpClient.get(`${this.API_URL2}/api/providers/listings/get/` + id + `?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    addZ(n) {
        return n < 10 ? '0' + n : '' + n;
    }

//need
    getDoctors(language) {
        return this.httpClient.get(`${this.API_URL2}/api/doctors/listings/get?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getProviders(language) {
        return this.httpClient.get(`${this.API_URL2}/api/providers/listings/get?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getAppointments(dataload) {
        return this.httpClient.get(`${this.API_URL}/api/appointments/get/all` + dataload, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getAppointmentsTest(dataload) {
        return this.httpClient.get(`${this.API_URL}/v1/appointment/missed` + dataload, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getMissedAppointments(from, to, type) {
        return this.httpClient.get(`${this.API_URL}/v1/appointment/missed` + "fromDateStr=" + from + " 00:00:00&toDateStr=" + to + " 00:00:00" + "&bookingType=" + type + "&page=", {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    ForgotPasswordByID(resetObj) {
        return this.httpClient.post(`${this.API_URL2}/api/users/password_reset`, resetObj, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }


    //need
    ForgotPassword(resetObj) {
        return this.httpClient.post(`${this.API_URL2}/api/users/forget_password`, resetObj, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getUserDetail(data) {
        return this.httpClient.post(`${this.API_URL}/api/user`, data, {headers: {'Content-Type': 'application/json'}});
    }



    getDoctorDetail() {
        return this.httpClient.get(`${this.API_URL}/v1/doc/`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    fareList(language) {
        return this.httpClient.get(`${this.API_URL3}/api/fare?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    fareListUpdate(data) {
        return this.httpClient.put(`${this.API_URL3}/api/fare`, data, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getAlerts() {
        return this.httpClient.get(`${this.API_URL}/v1/appointment/missed/alert`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    doctorActiveStatus(status, ID) {
        return this.httpClient.delete(`${this.API_URL}/v1/doc/` + ID + `/status/` + status, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    ForgotPasswordByEmail(resetObj) {
        return this.httpClient.post(`${this.API_URL}/v1/user/password/reset`, resetObj, {headers: {'Content-Type': 'application/json'}});
    }

//need
    loadCommission() {
        return this.httpClient.get(`${this.API_URL}/v1/commission/doctor_Commission_rate`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    UpdateCommission(data) {
        return this.httpClient.put(`${this.API_URL}/v1/commission/doctor_Commission_rate`, data, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    appointmentType() {
        return this.httpClient.get(`${this.API_URL}/v1/master/booking_type`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }


    createPatientBooking(data) {
        return this.httpClient.post(`${this.API_URL}/v1/patient`, data, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    availabilityLocations() {
        return this.httpClient.get(`${this.API_URL}/v1/location/availability`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getPatientSummary(patientId: number) {
        return this.httpClient.get(`${this.API_URL}/v1/patient/patient_summary?patientId=` + patientId, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getPatientV2() {
        return this.httpClient.get(`${this.API_URL}/v2/patient`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

//need
    getPatientInfoV2(patientId) {
        return this.httpClient.get(`${this.API_URL}/v2/patient/` + patientId, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    // getPromos0() {
    //     return this.httpClient.get(`${this.API_URL}/v1/promo_admin`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'User-Authorization-Token': localStorage.getItem('authToken')
    //         }
    //     });
    // }

    getPromos() {
        return this.httpClient.get(`${this.API_URL2}/api/promo/get`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    addPromo(data) {
        return this.httpClient.post(`${this.API_URL2}/api/promo/create`, data, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    updatePromoStatus(id, status) {
        return this.httpClient.put(`${this.API_URL2}/api/promo/` + id + '/' + status, null, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getPromoInfo(id) {
        return this.httpClient.get(`${this.API_URL2}/v1/promo_admin/` + id);
    }

    editPromoDetails(id, jsonData) {
        return this.httpClient.put(`${this.API_URL2}/api/promo/update/` + id, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getPromo(id) {
        return this.httpClient.get(`${this.API_URL2}/api/promo/get/id/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }


    deletePromos(id) {
        return this.httpClient.delete(`${this.API_URL2}/v1/promo_admin/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    assingDocTimeSlots() {
        return this.httpClient.get(`${this.API_URL}/api/timeslot`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    assingDocList(data) {
        // if(data.timeslot_id === undefined || data.timeslot_id == null || data.timeslot_id.length <= 0){
        //     return this.httpClient.get(`${this.API_URL}/v1/doc/eligibility?speciality_id=` + data.speciality_id + `&appointment_date=`+ data.appointment_date  + `&duration=` + data.duration ,  { headers: { 'Content-Type': 'application/json', 'User-Authorization-Token' :  localStorage.getItem('authToken') } });
        // }
        // return this.httpClient.get(`${this.API_URL}/v1/doc/eligibility?speciality_id=` + data.speciality_id + `&appointment_date=`+ data.appointment_date + `&timeslot_id=` + data.timeslot_id + `&duration=` + data.duration ,  { headers: { 'Content-Type': 'application/json', 'User-Authorization-Token' :  localStorage.getItem('authToken') } });
        return this.httpClient.get(`${this.API_URL2}/api/admin/timeslots/get?speciality=` + data.speciality_id + `&date=` + data.appointment_date + `&duration=` + data.duration+ `&slot_time=` + data.slot_time, {headers: {'Content-Type': 'application/json'}});
    }

    assignProviderList(data) {
        // if(data.timeslot_id === undefined || data.timeslot_id == null || data.timeslot_id.length <= 0){
        //     return this.httpClient.get(`${this.API_URL}/v1/doc/eligibility?speciality_id=` + data.speciality_id + `&appointment_date=`+ data.appointment_date  + `&duration=` + data.duration ,  { headers: { 'Content-Type': 'application/json', 'User-Authorization-Token' :  localStorage.getItem('authToken') } });
        // }
        // return this.httpClient.get(`${this.API_URL}/v1/doc/eligibility?speciality_id=` + data.speciality_id + `&appointment_date=`+ data.appointment_date + `&timeslot_id=` + data.timeslot_id + `&duration=` + data.duration ,  { headers: { 'Content-Type': 'application/json', 'User-Authorization-Token' :  localStorage.getItem('authToken') } });
        return this.httpClient.get(`${this.API_URL2}/api/admin/provider/timeslots/get?providerType=` + data.provider_type_id + `&date=` + data.appointment_date + `&duration=` + data.duration+ `&slot_time=` + data.slot_time, {headers: {'Content-Type': 'application/json'}});
    }

    assingDocSubmit(reference, data) {
        // return this.httpClient.put(`${this.API_URL}/v1/appointment/assign_doctor/` + reference, data,  { headers: { 'Content-Type': 'application/json', 'User-Authorization-Token' :  localStorage.getItem('authToken') } });
        return this.httpClient.get(`${this.API_URL2}/api/admin/doctor/assign?id=` + data.timeslotId + `&appointmentRef=` + reference + `&doctor_id=` + data.doctorId);
    }

    assignProviderSubmit(reference, data) {
        // return this.httpClient.put(`${this.API_URL}/v1/appointment/assign_doctor/` + reference, data,  { headers: { 'Content-Type': 'application/json', 'User-Authorization-Token' :  localStorage.getItem('authToken') } });
        return this.httpClient.get(`${this.API_URL2}/api/admin/provider/assign?id=` + data.timeslotId + `&appointmentRef=` + reference + `&user_id=` + data.doctorId+ `&slot_time=` + data.slot_time);
    }

    cancleAppoinment(reference) {
        return this.httpClient.put(`${this.API_URL}/api/appointment/cancel/` + reference, null, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    logoutUser(id) {
        return this.httpClient.put(`${this.API_URL}/v1/user/invalidate/` + id, null, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }


    deleteSpecialitySymptom(data,) {
        return this.httpClient.request('delete', `${this.API_URL}/api/speciality_symptom`, {
            body: data,
            headers: {'Content-Type': 'application/json', 'User-Authorization-Token': localStorage.getItem('authToken')}
        })
    }

    addSpecialitySymptom(data) {
        return this.httpClient.post(`${this.API_URL}/api/speciality_symptom`, data, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    doctorLocations() {
        return this.httpClient.get(`${this.API_URL}/v1/doc/location`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getMasterSpeciality(language) {
        return this.httpClient.get(`${this.API_URL}/v1/master-data/speciality?lang=` + language, {headers: {'Content-Type': 'application/json'}});
    }




    getSpeciality(language) {
        return this.httpClient.get(`${this.API_URL3}/api/speciality?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getSpecialitySymptomsByID(id, language) {
        return this.httpClient.get(`${this.API_URL3}/api/speciality/` + id + `/symptom?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    /**
     * get speciality symptoms by symptom id
     * @param id
     */
    getSymptomsByID(id) {
        return this.httpClient.get(`${this.API_URL3}/api/symptoms/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    /**
     * get all the symptoms according to the language
     * @param language
     */
    getSymptomsList(language) {
        return this.httpClient.get(`${this.API_URL3}/api/symptoms?lang=` + language, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    createSymptoms(data) {
        return this.httpClient.post(`${this.API_URL3}/api/symptoms`, data, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    updateSymptoms(id, data) {
        return this.httpClient.put(`${this.API_URL3}/api/symptoms/` + id, data, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    deleteSymptoms(id, UID) {
        return this.httpClient.delete(`${this.API_URL3}/api/symptoms/` + id + `?user_id=` + UID, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getSpecialityCheckList(id) {
        return this.httpClient.get(`${this.API_URL2}/api/speciality/service/get/` + id, {headers: {'Content-Type': 'application/json'}});
    }

    SpecialityServiceEnable(SID, CID) {
        return this.httpClient.get(`${this.API_URL2}/api/speciality/service/enable?booking_type=` + CID + `&speciality=` + SID, {headers: {'Content-Type': 'application/json'}});
    }

    SpecialityServiceDisable(SID, CID) {
        return this.httpClient.get(`${this.API_URL2}/api/speciality/service/disable?booking_type=` + CID + `&speciality=` + SID, {headers: {'Content-Type': 'application/json'}});
    }

    AppointmentSettingstimeslots(data) {
        return this.httpClient.get(`${this.API_URL2}/api/doctors/timeslots/add?doctor_id=` + data.docId + `&date=` + data.date + `&start_time=` + data.STime + `&end_time=` + data.ETime + `&addall=` + data.addAll + `&speciality=` + data.specility, {headers: {'Content-Type': 'application/json'}});
    }

    ProviderSettingsTimeslots(data) {
        return this.httpClient.get(`${this.API_URL2}/api/providers/timeslots/add?provider_id=` + data.providerId + `&date=` + data.date + `&start_time=` + data.STime + `&end_time=` + data.ETime + `&addall=` + data.addAll + `&providerType=` + data.providerType, {headers: {'Content-Type': 'application/json'}});
    }

    AppointmentSettingsTabledata(data) {
        return this.httpClient.get(`${this.API_URL2}/api/doctors/timeslots/get?id=` + data.docId + `&date=` + data.date + `&speciality=` + data.speciality, {headers: {'Content-Type': 'application/json'}});
    }

    ProviderSettingsTabledata(data) {
        return this.httpClient.get(`${this.API_URL2}/api/providers/timeslots/get?id=` + data.providerId + `&date=` + data.date + `&providerType=` + data.providerType, {headers: {'Content-Type': 'application/json'}});
    }

    AppointmentSettingsDelete(data) {
        return this.httpClient.get(`${this.API_URL2}/api/doctors/timeslots/delete?id=` + data.docId, {headers: {'Content-Type': 'application/json'}});
    }

    ProviderSettingsDelete(data) {
        return this.httpClient.get(`${this.API_URL2}/api/providers/timeslots/delete?id=` + data.timeSlotId, {headers: {'Content-Type': 'application/json'}});
    }

    getDoctorsBySpecialty(id) {
        return this.httpClient.get(`${this.API_URL2}/api/speciality/doctors/get?id=` + id, {headers: {'Content-Type': 'application/json'}});
    }

    getProvidersByTypes(id) {
        return this.httpClient.get(`${this.API_URL2}/api/providerTypes/providers/get?id=` + id, {headers: {'Content-Type': 'application/json'}});
    }

    updatedAddDoctor(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/doctors/add`, jsonData, {headers: {'Content-Type': 'application/json'}});
    }

    updatedAddProvider(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/providers/add`, jsonData, {headers: {'Content-Type': 'application/json'}});
    }

    getB2bProviders() {
        return this.httpClient.get(`${this.API_URL2}/api/b2bProviders/get`, {headers: {'Content-Type': 'application/json'}});
    }

    getB2bPackages(id) {
        return this.httpClient.get(`${this.API_URL2}/api/b2bPackage/getAll/` + id, {headers: {'Content-Type': 'application/json'}});
    }

    getPackageById(id) {
        return this.httpClient.get(`${this.API_URL2}/api/userPackage/getAll/` + id, {headers: {'Content-Type': 'application/json'}});
    }

    getB2bPackageById(id) {
        return this.httpClient.get(`${this.API_URL2}/api/b2bPackage/get/` + id, {headers: {'Content-Type': 'application/json'}});
    }

    getB2bProviderById(id) {
        return this.httpClient.get(`${this.API_URL2}/api/b2bProviders/get/` + id, {headers: {'Content-Type': 'application/json'}});
    }

    getB2bPackageByProviderId(id) {
        return this.httpClient.get(`${this.API_URL2}/api/b2bPackage/get/provider/` + id, {headers: {'Content-Type': 'application/json'}});
    }

    addB2bPackage(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bPackage/add`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    addUserPackageSpecialities(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/userPackage/specialties/add`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    addB2bPackageSpecialities(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bPackage/specialties/add`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    deleteUserPackageSpecialities(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/userPackage/specialties/delete`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    deleteB2bPackageSpecialities(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bPackage/specialties/delete`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    editInsuranceProviders(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bProviders/edit`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    editB2bPackages(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bPackage/edit`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    getUserPackages(is_web: 1) {
        return this.httpClient.get(`${this.API_URL2}/api/userPackage/getAll`, {headers: {'Content-Type': 'application/json'}});
    }

    getInsuranceProviders() {
        return this.httpClient.get(`${this.API_URL2}/api/b2bProviders/get`, {headers: {'Content-Type': 'application/json'}});
    }

    getRecordings(language) {
        return this.httpClient.get(`${this.API_URL2}/api/recordings/get?lang=` + language, {headers: {'Content-Type': 'application/json'}});
    }

    getInsuranceUsers(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bProviders/users/get`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getUserPackageUsers(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/userPackage/users/get/report`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    getPackageReport(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bPackage/reports/get`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    addUserPackage(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/userPackage/add`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    addInsuranceProvider(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bProviders/add`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    editUserPackages(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/userPackage/edit`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    changeStatusB2bProviders(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bProviders/change_status`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    changeUserStatus(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/users/change_status`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    changeStatusB2bUsers(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bUsers/change_status`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    changeStatusB2bPackage(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/b2bPackage/change_status`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    changeStatusUserPackage(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/userPackage/change_status`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    b2bUsersUpload(fileToUpload: File, $b2bProviderId) {
        console.log(fileToUpload);
        const formData: FormData = new FormData();
        formData.append('fileKey', fileToUpload, fileToUpload.name);
        formData.append('b2bProviderId', $b2bProviderId);
        return this.httpClient.post(`${this.API_URL2}/api/b2bProviders/users/upload`, formData, {
            headers: {
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }

    downloadPdfReport() {
        return this.httpClient.get(`${this.API_URL2}/api/b2bPackage/reports/pdf/download`, {headers: {'Content-Type': 'application/json'}});
    }

    downloadExcelReport() {
        return this.httpClient.get(`${this.API_URL2}/api/b2bPackage/reports/excel/download`, {headers: {'Content-Type': 'application/json'}});
    }

    //need
    getPatients() {
        return this.httpClient.get(`${this.API_URL2}/api/patients/get`, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }
        });
    }



    //prover packages

    addProviderPackage(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/providerPackage/add`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    changeStatusProviderPackage(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/providerPackage/change_status`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    editProviderPackages(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/providerPackage/edit`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'User-Authorization-Token': localStorage.getItem('authToken')
            }

        });
    }

    getProviderPackages(is_web: 1) {
        return this.httpClient.get(`${this.API_URL2}/api/providerPackage/getAll`, {headers: {'Content-Type': 'application/json'}});
    }

    getProviderTypes(language) {
        return this.httpClient.get(`${this.API_URL2}/api/providerPackage/providerTypes/get?lang=` + language, {headers: {'Content-Type': 'application/json'}});
    }

    getProviderPackageById(id) {
        return this.httpClient.get(`${this.API_URL2}/api/providerPackage/getAll/` + id, {headers: {'Content-Type': 'application/json'}});
    }

    deleteRequest(jsonData) {
        return this.httpClient.post(`${this.API_URL2}/api/deleteRequest`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            }

        });
    }


}

