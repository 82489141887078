import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PatientListComponent } from './patient-list/patient-list.component';
import {DoctorListComponent} from './doctor-list/doctor-list.component';
import {FareListComponent} from './fare-list/fare-list.component';
import {LoginControlComponent} from './login-control/login-control.component';
import {DeleteRequestComponent} from './delete-request/delete-request.component';
import {HomeLayoutComponent} from './home-layout/home-layout.component';
import { DoctestComponent } from './doctest/doctest.component';
import {AppointmentListComponent} from './appointment-list/appointment-list.component';
import {MissedAppointmentListComponent} from './missed-appointment-list/missed-appointment-list.component';
import {StatsComponent} from './stats/stats.component';
import {PromosComponent} from './promos/promos.component';
import { ManageSymptomsComponent } from './manage-symptoms/manage-symptoms.component';
import {DashboardListComponent} from './dashboard-list/dashboard-list.component';
import { AppointmentSettingsComponent } from './appointment-settings/appointment-settings.component';
import { SearchDoctorTimeSessionComponent } from './search-doctor-time-session/search-doctor-time-session.component';
import { ServicesComponent } from './services/services.component';
import {InsuranceProviderComponent} from './insurance-provider/insurance-provider.component';
import { AuthGuard } from './core/auth.guard';
import { UserResolver } from './stats/user.resolver';
import { InsuranceUsersComponent } from './insurance-users/insurance-users.component';
import { PackageListingComponent } from './package-listing/package-listing.component';
import { PackageReportComponent } from './package-report/package-report.component';
import { UserPackageComponent } from './user-package/user-package.component';
import {UserPackageUsersComponent} from './user-package-users/user-package-users.component';
import {ProvidersComponent} from './providers/providers.component';
import {ProviderPackageComponent} from './provider-package/provider-package.component';
import {ProviderPackageUsersComponent} from './provider-package-users/provider-package-users.component';
import {ProviderSettingsComponent} from './provider-settings/provider-settings.component';
import {VideoRecordsComponent} from './video-records/video-records.component';

const routes: Routes = [
  
  { path: '',  redirectTo: 'login', pathMatch: 'full' },
  
  {
    path: 'login',
    component: LoginControlComponent, canActivate: [AuthGuard]
  },
  {
    path: 'delete-request',
    component: DeleteRequestComponent, canActivate: [AuthGuard]
  },
  {
      path:'portal',
      component: HomeLayoutComponent,
      children:[
        {
          path: 'services',
          component: ServicesComponent, resolve: { data: UserResolver}
        },
        // {
        //   path: 'search-doctor-time-session',
        //   component: SearchDoctorTimeSessionComponent, resolve: { data: UserResolver}
        // },
        {
          path: 'appointment-settings',
          component: AppointmentSettingsComponent, resolve: { data: UserResolver}
        },  {
          path: 'provider-settings',
          component: ProviderSettingsComponent, resolve: { data: UserResolver}
        },
          {
            path: 'manage-symptoms',
            component: ManageSymptomsComponent, resolve: { data: UserResolver}
          },
          {
            path: 'insurance-providers',
            component: InsuranceProviderComponent, resolve: { data: UserResolver}
          },
          {
            path: 'insurance-users/:id',
            component: InsuranceUsersComponent, resolve: { data: UserResolver}
          },
          {
            path: 'package-list/:id',
            component: PackageListingComponent, resolve: { data: UserResolver}
          },
          {
            path: 'package-report/:id',
            component: PackageReportComponent, resolve: { data: UserResolver}
          },
          {
            path: 'user-package',
            component: UserPackageComponent, resolve: { data: UserResolver}
          },
          {
            path: 'user-package-user/:id',
            component: UserPackageUsersComponent, resolve: { data: UserResolver}
          },
        {
            path: 'provider-package',
            component: ProviderPackageComponent, resolve: { data: UserResolver}
          },
          {
            path: 'provider-package-user/:id',
            component: ProviderPackageUsersComponent, resolve: { data: UserResolver}
          },
          {
            path: 'patients',
            component: PatientListComponent, resolve: { data: UserResolver}
          },
          {
            path: 'appointments/:id',
            component: AppointmentListComponent, resolve: { data: UserResolver}
          },

          {
            path: 'providers',
            component: ProvidersComponent, resolve: { data: UserResolver}
          },
          {
            path: 'doctors',
            component: DoctestComponent, resolve: { data: UserResolver}
          },
          {
            path: 'fare',
            component: FareListComponent, resolve: { data: UserResolver}
          },
          {
            path: 'stats',
            component: StatsComponent, resolve: { data: UserResolver}
            
          },
          {
            path: 'promo',
            component: PromosComponent, resolve: { data: UserResolver}
            
          },   {
            path: 'records',
            component: VideoRecordsComponent, resolve: { data: UserResolver}

          },
          {
            path: 'Locator',
            component: DashboardListComponent, resolve: { data: UserResolver}
          },
      ],
        runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload',useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
