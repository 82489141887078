import { Component, OnInit, AfterViewInit, Pipe, PipeTransform } from '@angular/core';
import { APIService } from '../api.service';
import { DataService } from "../data.service";
import 'datatables.net';
import 'datatables.net-bs4';
import { Router } from "@angular/router";
import * as moment from 'moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Snapshot } from 'elasticsearch';
import { OrderPipe } from 'ngx-order-pipe';
import { UserIdleService } from 'angular-user-idle';
import { Location } from '@angular/common';
import { AuthService } from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'safe' })


@Component({
  selector: 'app-doctest',
  templateUrl: './doctest.component.html',
  styleUrls: ['./doctest.component.css']
})
export class DoctestComponent implements OnInit, AfterViewInit, PipeTransform  {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};


  registerForm: FormGroup;
  editForm: FormGroup;
  submitted = false;
  email: FormControl;
  firstName: FormControl;
  address: FormControl;
  countryCode: FormControl;
  lastName: FormControl;
  title: FormControl;
  password: FormControl;
  startDate: FormControl;
  gmcNumber: FormControl;
  profilePicture: FormControl;

  private doctors: Array<object> = [];
  private active = {};
  private newDoctor = {};
  dataTable: any;
  public files: any;
  public files2: any;
  public viewDoctor = true;
  public deleteBox = false;
  public new_picture = "assets/app-assets/images/avater.png";
  public new_edit_picture = "assets/app-assets/images/avater.png";
  public fileToUpload: any;
  public fileToUpload2: any;
  public deleteConfirm = false;
  public reason: any;
  public user_role: boolean;
  private active2: Array<object> = [];
  private specialityList: Array<object> = [];
  private selectedSpecialityList: Array<object> = [];
  public docstatus = "not changed";
  public callstatus = "not changed";
  docID: string = ' ';
  docEmail: string = ' ';
  private editDoctorData : Array<object> = [];
  order: string = ' ';
  reverse: boolean = false;
  public docUID: any;
  selectedLanguage = localStorage.getItem('selectedLanguage');


  constructor(public apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe, private userIdle: UserIdleService, private location: Location, public authService: AuthService,public sanitizer:DomSanitizer) {




    var docId;
    var gateWayRef = firebase.database().ref("doctors");
    gateWayRef.on('value', function (snapshot) {
      snapshot.forEach(function (childSnapshot) {
        var childData = childSnapshot.val();
        var ref2 = firebase.database().ref('doctors/' + childSnapshot.val().docId + '/availibility')
        ref2.on('child_changed', function (snapshot) {
          window.location.reload();
        }, function (errorObject) {
        });
      });
    });

    var query = firebase.database().ref("doctors" + docId + "availibility");
    query.on('child_changed', function (snapshot) {

      var ref2 = firebase.database().ref('doctors' + snapshot.val().docId + 'availibility')
      ref2.on('child_changed', function (snapshot) {
      }, function (errorObject) {
      });
    }, function (errorObject) {
    });

  }


  ngOnChanges() {
  }

  sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit() {
    this.getDoctors();
    this.hideAllModals();
    this.apiService.getSpeciality(this.selectedLanguage).subscribe((data: any) => {
      this.specialityList = data.data;
      var status = data.status;
    }, error => { if(error.status == 0){this.logout()}});

    this.registerForm = this.formBuilder.group({
      address: ['', Validators.required],
      distance: 0,
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      gender: ['', Validators.required],
      gmcNumber: [],
      lastName: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      countryCode: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      profilePicture: [],
      rate: 0,
      speciality: null,
      specialityId: new FormControl(this.specialityList, Validators.required),
      title: ['', Validators.required],
      startDate: ['', Validators.required],
    });

    this.editForm = this.formBuilder.group({
      address: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      gender: ['', Validators.required],
      gmcNumber: [],
      lastName: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      countryCode: ['', Validators.required],
      profilePicture: [],
      speciality: null,
      specialityId: new FormControl(this.selectedSpecialityList, Validators.required),
      title: ['', Validators.required],
      startDate: ['', Validators.required],
    });

    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => { console.log(count), this.expireSession() });
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => { this.logout(), this.expireSessionNow() });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


  
  hideAllModals(){
    $('.modal').modal('hide') // closes all active pop ups.
    $('.modal-backdrop').remove() // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show')
  }
  expireSessionNow() {
    $('#expireSession').modal('hide')
  }
  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide')
  }


  logout() {
    this.logoutUser()
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
      });
  }

  logoutUser(){
    let id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }
  get f() { return this.registerForm.controls; }
  get f2() { return this.editForm.controls; }

  /**
   * add new doctor
   */
  onSubmit() {
    var specialtyIdSelect = [];
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }


    if (this.fileToUpload != undefined && this.fileToUpload.name != "") {
      let formData = new FormData();
      formData.append("profile_picture", this.fileToUpload, this.fileToUpload.name);
      this.apiService.addImage(formData).subscribe((val: any) => {
        if (val.status_code == 0) {
          this.registerForm.value.profilePicture = val.data;
          var i;
          for (i = 0; i < this.registerForm.value.specialityId.length; i++) {
            var id = this.registerForm.value.specialityId[i];
            specialtyIdSelect.push(id.id);
                 }
                var data = {
                  address: this.registerForm.value.address ,
                  distance: 0,
                  email: this.registerForm.value.email ,
                  firstName: this.registerForm.value.firstName ,
                  gender: this.registerForm.value.gender ,
                  gmcNumber: this.registerForm.value.gmcNumber ,
                  lastName: this.registerForm.value.lastName ,
                  mobileNumber: this.registerForm.value.mobileNumber ,
                  countryCode: this.registerForm.value.countryCode ,
                  password: this.registerForm.value.password ,
                  profilePicture: this.registerForm.value.profilePicture ,
                  rate: 0,
                  speciality: null,
                  specialityId: specialtyIdSelect.join(", ") ,
                  title: this.registerForm.value.title ,
                  startDate: "" ,
                }

          this.apiService.updatedAddDoctor(data).subscribe((data: any) => {
            var status = data;
             if (status.status_code == "0") {
               this.getDoctors();
               Swal.fire({ text: status.message, icon: 'success' });
              $("#newdoctor").modal('hide');
              this.newDoctor = {};
              this.new_picture = "assets/app-assets/images/avater.png";
            }
            else {
               Swal.fire({ text: status.message, icon: 'error' });
            }
          });
        }
        else {
          Swal.fire({ text: val.status.reason, icon: 'error' });

        }
      }, error => { if(error.status == 0){this.logout()}});
    }
    else {
      Swal.fire({ text: "Please add a Profile Image", icon: 'error' });

    }
  }

  public addFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.new_picture = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      this.fileToUpload = event.target.files[0];
    }
  }
  public editFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {

        this.new_edit_picture = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
      this.fileToUpload2 = event.target.files[0];
    }
  }

  /**
   * edit doctor
   * @param editDoctor
   */
  public editDoctor(editDoctorData) {
    //editDoctor["profilePicture"] = editDoctor.profilePicture;
    var specialtyIdSelect = [];

    if (this.fileToUpload2 != undefined && this.fileToUpload2.name != "") {
      let formData = new FormData();
      formData.append("profile_picture", this.fileToUpload2, this.fileToUpload2.name);
      this.apiService.addImage(formData).subscribe((val: any) => {
        if (val.status_code == 0) {
          var i;
          for (i = 0; i < this.editForm.value.specialityId.length; i++) {
            var id = this.editForm.value.specialityId[i];
            specialtyIdSelect.push(id.id);
          }
          var data = {
            firstName: this.editForm.value.firstName,
            lastName: this.editForm.value.lastName,
            email: this.editForm.value.email,
            mobileNumber: this.editForm.value.mobileNumber,
            gmcNumber: this.editForm.value.gmcNumber,
            profilePicture: val.data,
            gender: this.editForm.value.gender,
            address: this.editForm.value.address,
            title: this.editForm.value.title,
            startDate: this.editForm.value.startDate.year + '-' + this.editForm.value.startDate.month + '-' + this.editForm.value.startDate.day,
            countryCode: this.editForm.value.countryCode,
            speciality: null,
            specialityId: specialtyIdSelect.join(", ") ,
          }


          this.apiService.editDoctor(editDoctorData.id, data).subscribe((data: any) => {
            var status = data.status_code;
            if (status == 0) {
              Swal.fire({ text: data.message, icon: 'success' });
             // $("#editdoctor").modal('hide');
             // window.location.reload();
            }
            else {
              Swal.fire({text: data.message, icon: 'error'});
            }
          }, error => { if(error.status == 0){$("#editdoctor").modal('hide');this.logout()}});

        }
        else {
          Swal.fire({text: val.status.reason, icon: 'error'});
        }
      }, error => { if(error.status == 0){this.logout()}});
    }
    else {
      var i;
      for (i = 0; i < this.editForm.value.specialityId.length; i++) {
        var id = this.editForm.value.specialityId[i];
        specialtyIdSelect.push(id.id);
      }
      var data = {
        firstName: this.editForm.value.firstName,
        lastName: this.editForm.value.lastName,
        email: this.editForm.value.email,
        mobileNumber: this.editForm.value.mobileNumber,
        gmcNumber: this.editForm.value.gmcNumber,
        profilePicture: this.new_edit_picture,
        gender: this.editForm.value.gender == 1 ? "MALE": "FEMALE",
        address: this.editForm.value.address,
        title: this.editForm.value.title,
        startDate: this.editForm.value.startDate.year + '-' + this.editForm.value.startDate.month + '-' + this.editForm.value.startDate.day,
        countryCode: this.editForm.value.countryCode,
        speciality: null,
        specialityId: specialtyIdSelect.join(", ") ,
      }

      this.apiService.editDoctor(editDoctorData.id, data).subscribe((data: any) => {
        var status = data.status_code;
        if (status == 0) {
          this.getDoctors();
          $("#editdoctor").modal('hide');
          Swal.fire({ text: data.message, icon: 'success' });
          // window.location.reload();
        }
        else {
          Swal.fire({text: data.message, icon: 'error'});
        }
      }, error => {
        // if(error.status == 0){this.logout()}
      });
    }
  }


  ngAfterViewInit() {
    setTimeout(() => {
      const table: any = $('.bootstrap-3')
      this.dataTable = table.DataTable();
      /*var dropdown1 = new uldropdown({
                      dropid: 'dropdown1', // id of menu DIV container
                      overlay: false, // true = drop down, false = expanding menu
                      onSelect($selected){ // when user selects a value
                              $('#output').val('Selected Text: ' + $selected.text() + '\n\n' + 'Selected Value: ' + $selected.attr('href'))
                              console.log($selected.text())
                      }
      })
  var dropdown2 = new uldropdown({
                  dropid: 'dropdown2', // id of menu DIV container
                  overlay: false, // true = drop down, false = expanding menu
                  onSelect($selected){ // when user selects a value
                          $('#output').val('Selected Text: ' + $selected.text() + '\n\n' + 'Selected Value: ' + $selected.attr('href'))
                          console.log($selected.text())
                  }
  });*/

    }, 1000)

  }




  /**
   * get doctors
   */
  public getDoctors() {
    this.apiService.getDoctors(this.selectedLanguage).subscribe((data: any) => {
      this.doctors = data.data;
     // this.selectedSpecialityList = data.data.specialties;

    //  this.doctors['profilePicture'] = data.data.profilePicture!=''?data.data.profilePicture :'assets/app-assets/images/avater.png'

      var status = data.status;
    }, error => { if(error.status == 0){this.logout()}});
  }
  public PassReset() {
    var data = {
      typeId: 2,
      userId: this.docID,
      email: this.docEmail
    }

    this.apiService.ForgotPassword(data).subscribe((data: any) => {
      var status = data.status_code;
      if (status == "0") {
       // alert("Successfully send the password reset email");
        Swal.fire({
          text: 'Successfully sent the password reset email.',
          icon: 'success'
        });

      }
      else {
        Swal.fire({
          text: 'Failed to send the password reset email!',
          icon: 'error'
        });
      }
    }, error => { if(error.status == 0){$("#viewdoctor").modal('hide');this.logout()}});

  }

  /**
   * doctor view
   * @param dd
   */
  public showDoctor(dd) {

    this.docID = dd.id;
    this.docEmail = dd.email;

    this.apiService.getDoctorInfo(dd.id,this.selectedLanguage).subscribe((data: any) => {
      data.data.genderV = data.data.gender == "MALE" ? 1 : 2;
      data.data.completedAppointments = dd.completedAppointments;
      data.data.missedAppointments = dd.missedAppointments;
      data.data.rejectedAppointments = dd.rejectedAppointments;
      data.data.callCompletedAppointments = dd.callCompletedAppointments;
      data.data.callMissedAppointments = dd.callMissedAppointments;
      data.data.callRejectedAppointments = dd.callRejectedAppointments;
      this.active = data.data;
      this.new_edit_picture = data.data.profilePicture;
      $("#viewdoctor").modal('show');
      var status = data.status;
    }, error => { if(error.status == 0){$("#viewdoctor").modal('hide'); this.logout()}});

  }

  /**
   * show edit doctor
   * @param dd
   */
  public showEditDoctor(dd) {
    this.selectedSpecialityList = [];
    this.docID = dd.id;
    this.docEmail = dd.email;

    this.apiService.getDoctorInfo(dd.id,this.selectedLanguage).subscribe((data: any) => {

      this.editDoctorData = data.data;
      this.selectedSpecialityList = data.data.specialties;

      const consultation_start_date = new Date(data.data.consultation_start_date);

      data.data.consultation_start_date_array = {
        year: consultation_start_date.getFullYear(),
        month: consultation_start_date.getMonth() + 1,
        day: consultation_start_date.getDate(),
      };

      console.log( this.editDoctorData);

      this.editForm.setValue({
        'firstName':   this.editDoctorData['firstName'],
        'lastName':   this.editDoctorData['lastName'],
        'email':   this.editDoctorData['email'],
        'gmcNumber':   this.editDoctorData['gmcNumber'],
        'mobileNumber':   this.editDoctorData['partialMobileNumber'],
        'countryCode':   this.editDoctorData['countryCode']!=null?this.editDoctorData['countryCode']:'',
        'title':   this.editDoctorData['title'],
        'gender':   this.editDoctorData['gender'],
        'profilePicture':   this.editDoctorData['profilePicture'],
        'address':   this.editDoctorData['address'],
        'startDate':   data.data.consultation_start_date_array,
        'speciality':   this.editDoctorData['speciality'],
        // 'specialityId': this.selectedSpecialityList
        'specialityId':   this.editDoctorData['specialityId'],
      });


      $("#editDoctor").modal('show');
      var status = data.status;
    }, error => { if(error.status == 0){$("#editDoctor").modal('hide'); this.logout()}});

  }

  public changeCallStatus(data) {

    this.docUID = localStorage.getItem("userId");


    this.apiService.updateDoctorCallStatus(data.id, data.callAvailability, this.docUID).subscribe((dd: any) => {
      var status = data.status;
    }, error => { if(error.status == 0){this.logout()}});
  }

  public changeStatus(data) {

    this.docUID = localStorage.getItem("userId");

    this.apiService.updateDoctorStatus(data.id, data.online, this.docUID).subscribe((dd: any) => {
      var status = data.status;
    }, error => { if(error.status == 0){this.logout()}});
  }

  public getStatus(data) {
    var response = "Available";
    /*if (data.online) {
        response = "Online";
    }*/
    if (data.inConsultation) {
      response = "In a consultation";
    }
    return response;
  }

  public getStatusClass(data) {
    var response = "default";
    /*if (data.online) {
        response = "green";
    }*/
    if (data.inConsultation) {
      response = "red";
    }
    return response;
  }

  public showDelete() {
    this.deleteBox = true;
    this.viewDoctor = false;
    this.deleteConfirm = false;
  }

  public hideDelete() {
    this.deleteBox = false;
    this.viewDoctor = true;
    this.deleteConfirm = false;
  }

  public doDelete() {
    this.deleteConfirm = true;
  }

  public confirmDelete(item) {
    this.apiService.deleteDoctor(item, this.reason).subscribe((data: any) => {
      alert("deleted successfully");
      $("#viewdoctor").modal('hide');
      this.hideDelete();
      window.location.reload();
      var status = data.status;
    }, error => { if(error.status == 0){this.logout()}});
  }

  public joinedDate(active) {
    var response = active.joinedDate;
    var responseTime = moment(response).format('ddd, Do MMM YYYY');
    return responseTime;
  }

  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  /**
   * change doctor status
   * @param data
   */
  public changeDoctorStatus(data) {
    this.apiService.changeUserStatus(data).subscribe((data: any) => {
      const status = data.status_code;
      if (status == 0) {
        this.getDoctors();
        Swal.fire({ text: data.message, icon: 'success' });
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if(error.status_code == 1){this.logout()}});
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}



