import {Component, OnInit } from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';
import {ValidationService} from '../validation.service';

@Component({
  selector: 'app-package-listing',
  templateUrl: './package-listing.component.html',
  styleUrls: ['./package-listing.component.css']
})
export class PackageListingComponent implements OnInit {

  newB2bPackageForm: FormGroup;
  editB2bPackageForm: FormGroup;
  addSpecialityForm: FormGroup;
  deleteSpecialityServiceForm: FormGroup;

  submitted = false;
  public b2bPackages: Array<object> = [];
  private newB2bPackages = {};
  private editB2bPackage = {};
  private specialityList: Array<object> = [];
  private b2bProvidersList: Array<object> = [];

  dataArr: any[];

  packageSearch;


  constructor(private validationService: ValidationService, private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) { }

  ngOnInit() {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession(); } );

    // Start watch when time is up.
    this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow(); } );


    this.newB2bPackageForm = this.formBuilder.group({
      b2b_provider_id: [this.route.snapshot.paramMap.get('id'), Validators.required],
      package_name: ['', [Validators.required]],
    //  slot_count: ['', [Validators.required, Validators.min(1), this.validationService.numericValidator ]],
      promo_code: '',
      usage_type: '',
      is_active: ''
    });

    this.editB2bPackageForm = this.formBuilder.group({
      package_id: ['', Validators.required],
      package_name: ['', [Validators.required]],
    //  slot_count: ['', [Validators.required, Validators.min(1), this.validationService.numericValidator ]],
      promo_code: '',
      usage_type: '',
      is_active: ''
    });

    this.addSpecialityForm = this.formBuilder.group({
      speciality_id: ['', Validators.required],
      slot_count: ['', [Validators.required, Validators.min(1), this.validationService.numericValidator ]],
      booking_type_id: ['', Validators.required],
       package_id : '',
    });

    this.deleteSpecialityServiceForm = this.formBuilder.group({
      speciality_id: ['', Validators.required],
      slot_count: ['', [Validators.required, Validators.min(1), this.validationService.numericValidator ]],
      booking_type_id: ['', Validators.required],
      package_id : ['', [Validators.required]],
    });

    this.apiService.getSpeciality('EN').subscribe((data: any) => {
      this.specialityList = data.data;
      const status = data.status;
    }, error => { if (error.status == 0) {this.logout(); }});

    this.apiService.getB2bProviders().subscribe((data: any) => {
      this.b2bProvidersList = data.data;
      const status = data.status_code;
    }, error => { if (error.status == 0) {this.logout(); }});

     this.getB2bPackages(this.route.snapshot.paramMap.get('id'));

  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show');
  }

  expireSessionNow() {
    $('#expireSession').modal('hide');
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
  }

  logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
        .then((res) => {
          this.location.back();
        }, (error) => {
        });
  }


  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
    });
  }

  get f1() { return this.newB2bPackageForm.controls; }
  get f2() { return this.editB2bPackageForm.controls; }
  get f3() { return this.addSpecialityForm.controls; }

  public getB2bPackages(b2bProviderId= '') {

    const b2bProvider = (b2bProviderId != '') ? b2bProviderId : this.newB2bPackageForm.value.b2b_provider_id;

    this.apiService.getB2bPackages(b2bProvider).subscribe((data: any) => {
      this.b2bPackages = data.data;
      // var status = data.status_code;
    }, error => {});
  }


  onSubmit() {
    this.submitted = true;

    if (this.newB2bPackageForm.invalid) {
      Swal.fire({text: 'Required fields are missing', icon: 'error'});
      return;
    }

    const b2bPackageData = {
      b2b_provider_id: this.newB2bPackageForm.value.b2b_provider_id,
      package_name: this.newB2bPackageForm.value.package_name,
      promo_code: this.newB2bPackageForm.value.promo_code,
     // slot_count: this.newB2bPackageForm.value.slot_count,
      usage_type: this.newB2bPackageForm.value.usage_type === true ? 1 : 0,
      is_active: this.newB2bPackageForm.value.is_active === true ? 1 : 0
    };

    this.apiService.addB2bPackage(b2bPackageData).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
        if (status == 0) {
          this.newB2bPackageForm.reset();
          Swal.fire({ text: data.message, icon: 'success' });
          this.getB2bPackages(this.route.snapshot.paramMap.get('id'));
          $('#newB2bPackage').modal('hide');
          this.newB2bPackageForm.setValue({
            b2b_provider_id: this.route.snapshot.paramMap.get('id'),
            package_name: '',
            promo_code: '',
            usage_type: '',
            is_active: ''
          });
        } else {
          Swal.fire({text: data.message, icon: 'error'});
        }
      }, error => { if (error.status === 0) {$('#newB2bPackage').modal('hide'); this.logout(); }});

  }

  public editB2bPackages(editB2bPackageForm) {

    this.submitted = true;

    if (this.editB2bPackageForm.invalid) {
      return;
    }

    const data = {
      package_id: this.editB2bPackageForm.value.package_id,
      package_name: this.editB2bPackageForm.value.package_name,
    //  slot_count: this.editB2bPackageForm.value.slot_count,
      usage_type: this.editB2bPackageForm.value.usage_type === true ? 1 : 0,
      promo_code: this.editB2bPackageForm.value.promo_code,
      is_active: this.editB2bPackageForm.value.is_active === true ? 1 : 0
    };

    this.apiService.editB2bPackages(data).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.getB2bPackages(this.route.snapshot.paramMap.get('id'));
        this.editB2bPackageForm.reset();
        Swal.fire({ text: data.message, icon: 'success' });
        $('#editB2bPackage').modal('hide');
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if (error.status === 0) {$('#editB2bPackage').modal('hide'); this.logout(); }});
  }

  public addSpeciality(b2bPackageId) {

    this.submitted = true;

    if (this.addSpecialityForm.invalid) {
      return;
    }

    const data = {
      package_id: b2bPackageId,
      speciality_id: this.addSpecialityForm.value.speciality_id,
      slot_count: this.addSpecialityForm.value.slot_count,
      booking_type_id: this.addSpecialityForm.value.booking_type_id,
    };

    this.apiService.addB2bPackageSpecialities(data).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.showB2bPackage(b2bPackageId);
        this.addSpecialityForm.reset();
        Swal.fire({text: data.message, icon: 'success'});
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, );
  }


  public deleteB2bPackageSpecialitiesServices(b2bPackageId, specialityId, bookingTypeId) {

   // this.submitted = true;

     const data = {
      package_id: b2bPackageId,
      speciality_id: specialityId,
      booking_type_id: bookingTypeId,
    };

    this.apiService.deleteB2bPackageSpecialities(data).subscribe((data: any) => {
    //  this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.showB2bPackage(b2bPackageId);
        Swal.fire({ text: data.message, icon: 'success' });
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, );
  }


  public showB2bPackage(b2bPackageId) {

    this.apiService.getB2bPackageById(b2bPackageId).subscribe((data: any) => {
      const status = data.status_code;
      this.editB2bPackage = data.data;


      this.editB2bPackageForm.setValue({
        'package_name':  this.editB2bPackage['package_name'],
       // 'slot_count': this.editB2bPackage['slot_count'] ,
        'is_active' : this.editB2bPackage['is_active']  === 1 ? true : false,
        'package_id' : b2bPackageId,
        'promo_code' : this.editB2bPackage['promo_code'],
        // 'promo_code_id' : this.editB2bPackage['promo_code_id'],
        'usage_type' : this.editB2bPackage['usage_type'] === 1 ? true : false

      });

      this.dataArr = [];
      if (data.data.b2bPackageSpecialities.length > 0) {
        data.data.b2bPackageSpecialities.map((sPackage: any) => {
          sPackage.serviceTypes.map((service: any) => {
            const a = {
              specialityName: sPackage.speciality_name_en,
              typeEn: service.type_en,
              slotCount: service.slot_count,
              remainingSlotCount : service.slot_count - service.used_slot_count,
              specialityId : sPackage.speciality_id,
              bookingTypeId : service.id,
              package_id: b2bPackageId
            };
            this.dataArr.push(a);
          });
        });
      }
    }, error => { if (error.status == 0) {$('#editB2bPackage').modal('hide'); this.logout(); }} );
  }

  /**
   * change user status
   * @param data
   */
  public changeStatus(data) {

        this.apiService.changeStatusB2bPackage(data).subscribe((data: any) => {
          const status = data.status_code;
          if (status == 0) {
            this.getB2bPackages(this.newB2bPackageForm.value.b2b_provider_id);
            Swal.fire({ text: data.message, icon: 'success' });
          } else {
            Swal.fire({text: data.message, icon: 'error'});
          }
        }, error => { if (error.status_code == 1) {this.logout(); }});

  }


}
