import { Component, OnInit } from '@angular/core';
import { APIService } from '../api.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import 'datatables.net';
import 'datatables.net-bs4';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';
import { OrderPipe } from 'ngx-order-pipe';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';



@Component({
    selector: 'app-promos',
    templateUrl: './promos.component.html',
    styleUrls: ['./promos.component.css']
})

export class PromosComponent implements OnInit {

    constructor(private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) {

        const current = new Date();
        this.minDate = {
            year: current.getFullYear(),
            month: current.getMonth() + 1,
            day: current.getDate()
        };
    }

    get f() { return this.registerForm.controls; }
    get f2() { return this.promoEditForm.controls; }
    registerForm: FormGroup;
    promoEditForm: FormGroup;
    submitted = false;
    private active2: Array<object> = [];
    // private promos: Array<object> = [];
    private promos: Array<object> = [];
    private active = {};
    private newDoctor = {};
    private editDoctor = {};
    private Sample = {};
    public executed = Boolean;
    dataTable: any;
    public viewPromo = true;
    public deleteBox = false;
    public deleteConfirm = false;
    public user_role: boolean;
    public requestedFrom: any;
    public requestedTo: any;
    public validityRest = [];
    public others: any;
    public EditChecklist = [];
    public b2bProviders = {};
    public b2bPackagesForProvider : any;
    public userPackages = {};
    public hidden_b2b_package_details = true;
    public hidden_user_package_details = true;
    minDate: {year: number, month: number, day: number};


    order = ' ';
    reverse = false;

    public AddCheckList: any;

    public function;

    ngOnInit() {
        this.hideAllModals();

        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession(); } );

        // Start watch when time is up.
        this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow(); } );

        this.loadPromoData();

        this.registerForm = this.formBuilder.group({
            promoCode: ['', Validators.required],
            description: ['', Validators.required],
            endDate: ['', Validators.required],
            startDate: ['', Validators.required],
            promoTypeId: ['', Validators.required],
            value: ['', Validators.required],
            packageType: ['', Validators.required],
            package: '',
            b2bProvider: ''
        });
        this.promoEditForm = this.formBuilder.group({
            promoCode: ['', Validators.required],
            description: ['', Validators.required],
            endDate: ['', Validators.required],
            startDate: ['', Validators.required],
            promoTypeId: ['', Validators.required],
            value: ['', Validators.required],
            packageType: ['', Validators.required],
            package: '',
            b2bProvider: ''
        });

        let executed = false;
        return function () {
            if (!executed) {
                executed = true;
                // do something
                $('#refresh').load(location.href + '#refresh');
                $('#refresh').load(location.href + ' #refresh>*', '');
                window.location.reload();
            }
        };
    }


    loadPromoData() {
        this.apiService.getPromos().subscribe((data: any) => {
            this.promos = data.data;
            this.promos = (this.promos).reverse();
            console.log('onboard details', this.promos);
            const status = data.status_code;
        }, error => { if (error.status == 0) {this.logout(); }});

    }

    hideAllModals() {
        $('.modal').modal('hide'); // closes all active pop ups.
        $('.modal-backdrop').remove(); // removes the grey overlay.
      }

    expireSession() {
        $('#expireSession').modal('show');
      }

      expireSessionNow() {
        $('#expireSession').modal('hide');
      }

      Sessionrestart() {
        this.userIdle.resetTimer();
        $('#expireSession').modal('hide');
      }


      logout() {
        this.logoutUser();
        localStorage.clear();
        this.authService.doLogout()
          .then((res) => {
            this.location.back();
          }, (error) => {
            // console.log("Logout error", error);
          });
      }

      logoutUser() {
        const id = localStorage.getItem('userId');
        this.apiService.logoutUser(id).subscribe((data: any) => {
      });
      }


    /**
     * registration submit
     */
    onSubmit() {

        const a = new Date(this.registerForm.value.startDate.year + '-' + this.registerForm.value.startDate.month + '-' + this.registerForm.value.startDate.day + ' 00:00:00');
        const b = new Date(this.registerForm.value.endDate.year + '-' + this.registerForm.value.endDate.month + '-' + this.registerForm.value.endDate.day + ' 23:59:59');

        if ( a > b) {
            Swal.fire({text: 'Promo end date should be greater than the start date', icon: 'error'});
            return;
        }

        if (this.AddCheckList === undefined || this.AddCheckList.length == 0) {
            Swal.fire({text: 'Must select at least one booking type.', icon: 'error'});
            return;
        }

        this.submitted = true;
        const data = {
            bookingTypes: this.AddCheckList,
            description: this.registerForm.value.description,
            endDate: this.registerForm.value.endDate.year + '-' + this.addZ(this.registerForm.value.endDate.month) + '-' + this.addZ(this.registerForm.value.endDate.day) + ' 23:59:59',
            // maxTryNumberForPromo:null,
            // maxTryNumberForUser:null,
            promoCode: this.registerForm.value.promoCode,
            promoTypeId: this.registerForm.value.promoTypeId,
            // selective: false,
            startDate: this.registerForm.value.startDate.year + '-' + this.addZ(this.registerForm.value.startDate.month) + '-' + this.addZ(this.registerForm.value.startDate.day) + ' 00:00:00',
            // validityType:1,
            value: this.registerForm.value.value,
            // value1:this.registerForm.value.Visit ,
            // value2:this.registerForm.value.Schedule,
            // value3:this.registerForm.value.Call,
            // value4:this.registerForm.value.AdvanceInstance,
            // value5:this.registerForm.value.Video,
            packageType : this.registerForm.value.packageType,
            package : this.registerForm.value.package,
        };

        console.log(this.AddCheckList);

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            // alert('Unsuccessful!');
            return;
        } else {
            // console.log('till here',data);
            // alert('SUCCESS!! :-)' + data);
            this.apiService.addPromo(data).subscribe((data: any) => {
                this.submitted = false;
                const status = data.status_code;
                // console.log(status);
                 if (status == 0) {
                     this.registerForm.reset();
                     this.loadPromoData();
                     Swal.fire({ text: data.message, icon: 'success' });
                     $('#newdoctor').modal('hide');
                    window.location.reload();
                    this.newDoctor = {};
                } else {
                     Swal.fire({ text: data.message, icon: 'error' });
                }
            }, error => { if (error.status == 0) {$('#newdoctor').modal('hide'); this.logout(); }});
        }
    }


    ngAfterViewInit() {
        setTimeout(() => {
            const table: any = $('.bootstrap-3');
            this.dataTable = table.DataTable();
        }, 2000);
    }

    /**
     * promo status change as active/inactive
     * @param data
     */
    public changeStatus(data) {
        // console.log(data);
        let stat = 0;
        if (data.active == true) {
             stat = 1;
            // console.log(stat);
        } else {
             stat = 0;
            // console.log(stat);
        }
         console.log(stat);

        this.apiService.updatePromoStatus(data.promoId, stat).subscribe((data: any) => {
            // console.log(dd);
            const status = data.status_code;
            if (status == 0) {
                Swal.fire({ text: 'Promo status updated successfully', icon: 'success' });
               // window.location.reload();
            } else {
                Swal.fire({ text: 'Failed to update the promo code', icon: 'error' });
            }
        }, error => { if (error.status == 0) {
            // this.logout();
        }});
        let executed = false;
        return function () {
            if (!executed) {
                executed = true;
                // do something
                //    $("#refresh").load(location.href + "#refresh");
                $('#refresh').load(location.href + ' #refresh>*', '');
                //    window.location.reload();
            }
        };
    }

    public getStatus(data) {
        let response = 'Available';
        /*if (data.online) {
            response = "Online";
        }*/
        if (data.inConsultation) {
            response = 'In a consultation';
        }
        return response;
    }

    public getStatusClass(data) {
        let response = 'default';
        /*if (data.online) {
            response = "green";
        }*/
        if (data.inConsultation) {
            response = 'red';
        }
        return response;
    }

    /**
     * get all promos
     * @param arrNames
     */
    public getAllpromo(arrNames) {
        for (let i = 0; i < arrNames.length; i++) {
            // console.log(arrNames[i]);
            this.apiService.getPromoInfo(arrNames[i]).subscribe((data: any) => {
                this.data = data.data;
                data.data.startDate = this.promoStartTime(data.data.startDate);
                data.data.endDate = this.promoStartTime(data.data.endDate);
                // console.log(data.data.startDate);
                this.active2.push(data.data);
                const status = data.status;
            }, error => { if (error.status == 0) {this.logout(); }});
        }
    }


    public editPromoStatus(editPromo) {
        this.submitted = true;
        // stop here if form is invalid
        if (this.promoEditForm.invalid) {
            return;
        } else {

        }
        const data = {
            description: this.promoEditForm.value.description,
            endDate: this.promoEditForm.value.endDate + ' 23:59:59',
            maxTryNumberForPromo: null,
            maxTryNumberForUser: null,
            promoCode: this.promoEditForm.value.promoCode,
            promoTypeId: this.promoEditForm.value.promoTypeId,
            selective: false,
            startDate: this.promoEditForm.value.startDate + ' 00:00:00',
            validityType: 1,
            value: this.promoEditForm.value.value
        };

        this.apiService.editPromoDetails(editPromo.promoId, data).subscribe((data: any) => {
            const status = data.status;
            // console.log(status);
            if (status.status == 'SUCCESS') {
                alert('Promo updated successfully');
                window.location.reload();
                $('#editdoctor').modal('hide');
            } else {
                alert(status.reason);
            }
        }, error => { if (error.status == 0) {$('#editdoctor').modal('hide'); this.logout(); }});
    }

    /**
     * edit form submit
     * @param editPromo
     */
    public editPromo(editPromo) {
        this.submitted = true;
        // stop here if form is invalid

        if (this.EditChecklist.length == 0) {
            Swal.fire({text: 'Must select at least one booking type.', icon: 'error'});
            return;
        }

        if (this.promoEditForm.invalid) {
            console.log(this.promoEditForm);
            return;
        }
            const data = {

                bookingTypes: this.EditChecklist,
                description: this.promoEditForm.value.description,
                endDate: this.promoEditForm.value.endDate,
                promoCode: this.promoEditForm.value.promoCode,
                promoTypeId: this.promoEditForm.value.promoTypeId,
                startDate: this.promoEditForm.value.startDate,
                value: this.promoEditForm.value.value,
                packageType: this.promoEditForm.value.packageType,
                package: this.promoEditForm.value.package
            };
            this.apiService.editPromoDetails(editPromo.promoId, data).subscribe((data: any) => {
                const status = data.status_code;
                // console.log(status);
                if (status == '0') {
                    Swal.fire({text: data.message, icon: 'success'});
                     window.location.reload();
                    $('#editdoctor').modal('hide');
                } else {
                    Swal.fire({text: data.message, icon: 'error'});
                }
            }, error => {
                if (error.status == 0) {
                    $('#editdoctor').modal('hide');
                    this.logout();
                }
            });
    }

    public promoStartTime(data) {
        const responseTime = moment(data).format('YYYY-MM-DD');
        return responseTime;
    }

    public promoEndTime(data) {
        const responseTime = moment(data).format('YYYY-MM-DD');
        return responseTime;
    }

    public promoDate(data) {
        const responseTime = moment(data).format('YYYY-MM-DD');
        return responseTime;
    }

    addZ(n) { return n < 10 ? '0' + n : '' + n; }
    public addPromo(editPromo) {
        const data = {
            description: editPromo.description,
            endDate: this.requestedTo.year + '-' + this.addZ(this.requestedTo.month) + '-' + this.addZ(this.requestedTo.day) + ' 23:59:59',
            maxTryNumberForPromo: null,
            maxTryNumberForUser: null,
            promoCode: editPromo.promo_code,
            promoTypeId: editPromo.promoTypeId,
            selective: false,
            startDate: this.requestedFrom.year + '-' + this.addZ(this.requestedFrom.month) + '-' + this.addZ(this.requestedFrom.day) + ' 00:00:00',
            validityType: 1,
            value: editPromo.value
        };
        this.apiService.addPromo(data).subscribe((data: any) => {
            const status = data.status;
            // console.log(status);
            if (status.status == 'SUCCESS') {
                alert('Promo updated successfully');
                $('#newdoctor').modal('hide');
                window.location.reload();
                this.newDoctor = {};
            } else {
                Swal.fire({ text: data.message, icon: 'error' });
            }
        }, error => { if (error.status == 0) {$('#newdoctor').modal('hide'); this.logout(); }});
    }

    /**
     * show promo details to edit
     * @param dd
     * @param TableClick
     */
    public showPromo(dd, TableClick) {

        const array = dd.validityRestriction;
        for (let i = 0; i < array.length; i++) {
            if (array[i].bookingTypeId !== null ) {
                // console.log(array[i].bookingTypeId)
                this.validityRest.push(array[i].bookingTypeId);
                this.EditChecklist = this.validityRest;
                // console.log([this.EditChecklist])
            } else {
                this.EditChecklist = [];
                this.validityRest = [];
            }
        }
        $('#editdoctor').modal('show');
        this.deleteBox = false;
        this.apiService.getPromo(dd.promoId).subscribe((data: any) => {
            data.data.value = data.data.value;
            data.data.description = data.data.description;
            data.data.promoCode = data.data.promo_code;
            data.data.startDate = data.data.start_date;
            data.data.endDate = data.data.end_date;
            data.data.promoTypeId = data.data.promo_type_id;
            data.data.packageType = data.data.promo_package_type;
            data.data.package = data.data.package_id;
            data.data.b2bProvider = data.data.b2b_provider_id;
            data.data.promoId = data.data.id;

            console.log( data.data.package);

            this.editDoctor = data.data;
            this.promoEditForm.setValue({
                'value': data.data.value,
                'promoCode': data.data.promoCode,
                'startDate': data.data.startDate ,
                'endDate':  data.data.endDate,
                'description': data.data.description,
                'promoTypeId': data.data.promoTypeId,
                'packageType': data.data.packageType,
                'package': data.data.package,
                'b2bProvider': data.data.b2bProvider,
            });
            $('#editdoctor').modal('show');
            // console.log(data);
            const status = data.status;

            // show hide views according to the package types
            this.b2bProviders = [];
            this.userPackages = [];
            if (data.data.packageType == 1) {

                // get b2b companies
                this.getInsuranceProviders();
                this.getB2bPackageOfProvider(data.data.b2bProvider);

                // show b2b package option
                this.hidden_user_package_details = true;
                this.hidden_b2b_package_details = false;

            } else if (data.data.packageType == 2) {
                // show user package options
                this.getUserPackages();
                this.hidden_b2b_package_details = true;
                this.hidden_user_package_details = false;
            } else {
                this.hidden_b2b_package_details = true;
                this.hidden_user_package_details = true;
            }

        }, error => { if (error.status == 0) {$('#editdoctor').modal('hide'); this.logout(); }});
    }

    /**
     * view promo details
     * @param dd
     * @param TableClick
     */
    public viewPromoDetails(dd, TableClick) {
        const array = dd.validityRestriction;
        for (let i = 0; i < array.length; i++) {
            if (array[i].bookingTypeId !== null ) {
                // console.log(array[i].bookingTypeId)
                this.validityRest.push(array[i].bookingTypeId);
                this.EditChecklist = this.validityRest;
                // console.log([this.EditChecklist])
            } else {
                this.EditChecklist = [];
                this.validityRest = [];
            }
        }
        $('#viewPromo').modal('show');
        this.deleteBox = false;
        this.apiService.getPromo(dd.promoId).subscribe((data: any) => {
            data.data.value = data.data.value;
            data.data.description = data.data.description;
            data.data.promoCode = data.data.promo_code;
            data.data.startDate = data.data.start_date;
            data.data.endDate = data.data.end_date;
            data.data.promoTypeId = data.data.promo_type_id;
            data.data.packageType = data.data.promo_package_type;
            data.data.package = data.data.package_id;
            data.data.b2bProvider = data.data.b2b_provider_id;
            data.data.promoId = data.data.id;

            this.editDoctor = data.data;
            this.promoEditForm.setValue({
                'value': data.data.value,
                'promoCode': data.data.promoCode,
                'startDate': data.data.startDate ,
                'endDate':  data.data.endDate,
                'description': data.data.description,
                'promoTypeId': data.data.promoTypeId,
                'packageType': data.data.packageType,
                'package': data.data.package,
                'b2bProvider': data.data.b2bProvider,
            });
            $('#viewPromo').modal('show');

        }, error => { if (error.status == 0) {$('#viewPromo').modal('hide'); this.logout(); }});
    }

    public showDelete() {
        this.deleteBox = true;
        this.viewPromo = false;
        this.deleteConfirm = false;
    }

    public hideDelete() {
        this.deleteBox = false;
        this.viewPromo = true;
        this.deleteConfirm = false;
    }

    public doDelete() {
        this.deleteConfirm = true;
    }

    /**
     * show edit form
     * @param promo
     */
    public showEditPatient(promo) {
        $('#viewdoctor').modal('hide');
        this.apiService.getPromo(promo.promoId).subscribe((data: any) => {
            data.data.value = data.data.vlaue;
            data.data.description = data.data.description;
            data.data.startDate = this.promoStartTime(data.data.start_date);
            data.data.endDate = this.promoStartTime(data.data.end_date);
            data.data.promoCode = data.data.promo_code;
            data.data.promoType = data.data.promo_type_id;
            data.data.packageType = data.data.package_type;
            data.data.package = data.data.package_id;
            data.data.b2bProvider = data.data.b2b_provider_id;
            this.editDoctor = data.data;
            $('#editdoctor').modal('show');
            const status = data.status;
        }, error => { if (error.status == 0) {$('#editdoctor').modal('show'); this.logout(); }});
    }

    public confirmDelete(item) {
        this.apiService.deletePromos(item).subscribe((data: any) => {
            alert('deleted successfully');
            $('#viewdoctor').modal('hide');
            // this.getPromo();
            this.hideDelete();
            window.location.reload();
            const status = data.status;
        }, error => { if (error.status == 0) {$('#viewdoctor').modal('hide'); this.logout(); }});
    }




    ChecklistOnClick(event: [any]) {
        this.AddCheckList = event;
    }

    onSearchChange(searchValue: string): void {
        // console.log(searchValue);
    }

    public showPromoInfo(validityRest) {
        this.validityRest = validityRest;
        $('#PromoInfo').modal('show');
    }

    setOrder(value: string) {
        if (this.order === value) {
          this.reverse = !this.reverse;
        }
        this.order = value;
      }

    packageTypeChange() {
            const packageType = this.registerForm.value.packageType;

        this.registerForm.value.b2bProvider = '';
        this.registerForm.value.package = '';
            this.b2bProviders = [];
            this.userPackages = [];

        if (packageType == 1) {
                // show b2b package option
                this.hidden_user_package_details = true;
                this.hidden_b2b_package_details = false;

                // get b2b companies
                this.getInsuranceProviders();

            } else if (packageType == 2) {
               // show user package options
                this.hidden_b2b_package_details = true;
                this.hidden_user_package_details = false;
                this.getUserPackages();
            } else {
            this.hidden_b2b_package_details = true;
            this.hidden_user_package_details = true;

        }

        }

    packageTypeEditChange() {
        const packageType = this.promoEditForm.value.packageType;

        this.promoEditForm.value.b2bProvider = '';
        this.promoEditForm.value.package = '';
        this.b2bProviders = [];
        this.userPackages = [];
        if (packageType == 1) {
            // show b2b package option
            this.hidden_user_package_details = true;
            this.hidden_b2b_package_details = false;

            // get b2b companies
            this.getInsuranceProviders();

        } else if (packageType == 2) {
            // show user package options
            this.hidden_b2b_package_details = true;
            this.hidden_user_package_details = false;
            this.getUserPackages();
        } else {
            this.hidden_b2b_package_details = true;
            this.hidden_user_package_details = true;
        }

    }

    public getInsuranceProviders() {
        this.apiService.getInsuranceProviders().subscribe((data: any) => {
            this.b2bProviders = data.data;
        }, error => error);
    }

    public getB2bPackageOfProvider(b2bProvider) {
        this.apiService.getB2bPackageByProviderId(b2bProvider).subscribe((data: any) => {
            this.b2bPackagesForProvider = data.data;
            console.log(this.b2bPackagesForProvider);
        }, error => error );

    }

    public getUserPackages() {
        this.apiService.getUserPackages(1).subscribe((data: any) => {
            this.userPackages = data.data;
        }, error => error );

    }

    b2bProviderChange() {
        this.registerForm.value.package = '';
        const b2bProvider = this.registerForm.value.b2bProvider;
        this.getB2bPackageOfProvider(b2bProvider);
    }

    b2bProviderEditChange() {
        this.promoEditForm.value.package = '';
        const b2bProvider = this.promoEditForm.value.b2bProvider;
        this.getB2bPackageOfProvider(b2bProvider);
    }

}
