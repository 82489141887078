import {Component, OnInit } from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {ValidationService} from '../validation.service';

@Component({
  selector: 'provider-package',
  templateUrl: './provider-package.component.html',
  styleUrls: ['./provider-package.component.css']
})

export class ProviderPackageComponent implements OnInit {

  newProviderPackageForm: FormGroup;
  editProviderPackageForm: FormGroup;

  submitted = false;
  public providerPackages: Array<object> = [];

  private newUserPackages = {};
  private editProviderPackage = {};
  private providerTypes: Array<object> = [];
  dataArr: any[];
  packageSearch;
  selectedLanguage = localStorage.getItem('selectedLanguage');
  minDate: {year: number, month: number, day: number};


  constructor(private validationService: ValidationService, private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) {

    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

  }


  ngOnInit() {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession(); } );

    // Start watch when time is up.
    this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow(); } );

    this.getProviderPackages();
    this.getProviderTypes();

    this.newProviderPackageForm = this.formBuilder.group({
      package_name: ['', [Validators.required]],
      provider_type: ['', [Validators.required]],
      slot_count: ['', [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$") ]],
      amount: ['', [Validators.required ]],
      is_active: (''),
    });

    this.editProviderPackageForm = this.formBuilder.group({
      package_name: ['', Validators.required],
      package_id: ['', Validators.required],
      provider_type: ['', [Validators.required]],
      slot_count: ['', [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$") ]],
      amount: ['', [Validators.required]],
      is_active: '',
    });

  }

  public getProviderTypes() {

    this.apiService.getProviderTypes(this.selectedLanguage).subscribe((data: any) => {
      this.providerTypes = data.data;
      // var status = data.status_code;
    }, error => {});
  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show');
  }

  expireSessionNow() {
    $('#expireSession').modal('hide');
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
  }

  logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
        .then((res) => {
          this.location.back();
        }, (error) => {
        });
  }


  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
    });
  }

  get f1() { return this.newProviderPackageForm.controls; }
  get f2() { return this.editProviderPackageForm.controls; }



  public getProviderPackages() {
    this.apiService.getProviderPackages(1).subscribe((data: any) => {
      this.providerPackages = data.data;
      // var status = data.status_code;
    }, error => {});
  }


  /**
   *
   * add provider packages
   */
  onSubmit() {

    this.submitted = true;

    if (this.newProviderPackageForm.invalid) {
      return;
    }

    const data = {
      package_name: this.newProviderPackageForm.value.package_name,
      provider_type: this.newProviderPackageForm.value.provider_type,
      slot_count: this.newProviderPackageForm.value.slot_count,
      amount: this.newProviderPackageForm.value.amount,
      is_active: this.newProviderPackageForm.value.is_active === true ? 1 : 0,

    };

    this.apiService.addProviderPackage(data).subscribe((data: any) => {
      this.submitted = true;
      const status = data.status_code;
      if (status == 0) {
        this.getProviderPackages();
        Swal.fire({ text: data.message, icon: 'success' });
        this.newProviderPackageForm.reset();
        $('#newUserPackage').modal('hide');
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if (error.status == 0) {$('#newUserPackage').modal('hide'); this.logout(); }});

  }


  public editProviderPackages(editProviderPackage) {


    this.submitted = true;

    if (this.editProviderPackageForm.invalid) {

      console.log(this.editProviderPackageForm);
      return;
    }

    const editData = {
      package_id: this.editProviderPackageForm.value.package_id,
      package_name: this.editProviderPackageForm.value.package_name,
      slot_count: this.editProviderPackageForm.value.slot_count,
      amount: this.editProviderPackageForm.value.amount,
      is_active: this.editProviderPackageForm.value.is_active === true ? 1 : 0,
      provider_type: this.editProviderPackageForm.value.provider_type

    };

    this.apiService.editProviderPackages(editData).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.getProviderPackages();
        Swal.fire({ text: data.message, icon: 'success' });
        this.editProviderPackageForm.reset();
        $('#editUserPackage').modal('hide');
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, );
  }



  public showProviderPackage(packageId) {
    this.apiService.getProviderPackageById(packageId).subscribe((data: any) => {
      const status = data.status_code;


      this.editProviderPackage = data.data;

      console.log(data.data);

      this.editProviderPackageForm.setValue({
        'package_name':  this.editProviderPackage['package_name'],
        'slot_count': this.editProviderPackage['slot_count'] ,
        'amount': this.editProviderPackage['amount'],
        'is_active' : this.editProviderPackage['is_active'] === 0 ? false : true,
        'package_id' : packageId,
        'provider_type' : this.editProviderPackage['provider_type'] ,
      });
    }, error => { if (error.status == 0) {$('#editUserPackage').modal('hide'); this.logout(); }} );
  }

  public StartTime(data) {
    const responseTime = moment(data).format('YYYY-MM-DD');
    return responseTime;
  }

  public EndTime(data) {
    const responseTime = moment(data).format('YYYY-MM-DD');
    return responseTime;
  }

  /**
   * change provider package status
   * @param data
   */
  public changeStatus(data) {
    this.apiService.changeStatusProviderPackage(data).subscribe((data: any) => {
      const status = data.status_code;
      if (status == 0) {
        this.getProviderPackages();
        Swal.fire({ text: data.message, icon: 'success' });
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if (error.status_code == 1) {this.logout(); }});
  }

}
