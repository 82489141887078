import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators'; 
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { APIService } from '../api.service';
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";

@Injectable()
export class AuthService {
  constructor(
   public afAuth: AngularFireAuth, private apiService: APIService, private router: Router
 ){}

 
  doRegister(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  doLogin(value){
    return new Promise<any>((resolve, reject) => {
      var data = {
        email: value.email,
        password: value.password
    }
    this.apiService.getUserDetail(data).subscribe((data: any) => {
        resolve(data);

    }, error => reject(error));
    })
  }

  doLogout(){
    return new Promise((resolve, reject) => {
      this.router.navigate(['/login']);
      // this.router.navigateByUrl('/login');
      localStorage.clear();
      resolve();
    });
  }


}
