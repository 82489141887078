import {AfterViewInit, Component, OnInit} from '@angular/core';
import {APIService} from '../api.service';
import { DataService } from "../data.service";
import 'datatables.net';
import 'datatables.net-bs4';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';


@Component({
  selector: 'app-package-report',
  templateUrl: './package-report.component.html',
  styleUrls: ['./package-report.component.css']
})

export class PackageReportComponent implements OnInit {

  public packageReportDetails: Array<object> = [];
  dataTable: any;
  packageReportSearch;
  b2bPackageId: any;

  constructor(private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) { }

  ngOnInit() {
    this.b2bPackageId = this.route.snapshot.paramMap.get('id');
    this.getPackageReport();

  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show');
  }

  expireSessionNow() {
    $('#expireSession').modal('hide');
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
  }

  logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
        .then((res) => {
          this.location.back();
        }, (error) => {
          // console.log("Logout error", error);
        });
  }


  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
    });
  }

  public getPackageReport() {

    const data = {
      b2b_package_id: this.b2bPackageId
    };

    this.apiService.getPackageReport(data).subscribe((data: any) => {
      this.packageReportDetails = data.data;
      setTimeout(() => {
        const table: any = $('.bootstrap-3');
        this.dataTable = table.DataTable({
          processing: true,
          dom: 'lrtpB',
          buttons: [
            {
              extend: 'excel',
              text: 'Download XLS',
              className: 'buttons-excel'
            },
            {
              extend: 'pdf',
              text: 'Download PDF',
              className: 'buttons-pdf'
            }
          ]
        });
      });    }, error => {});
  }

  public downloadPdfReport() {
    this.apiService.downloadPdfReport().subscribe((data: any) => {
      return data;
    }, error => {});
  }

  public downloadExcelReport() {
    this.apiService.downloadExcelReport().subscribe((data: any) => {
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = "help.pdf";
      link.click();
      }, error => {});
  }

}
