import { Component, OnInit } from '@angular/core';
import {DataService} from './data.service';
import 'datatables.net';
import 'datatables.net-bs4';
 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit  {
  title = 'ngadmin';
  dataTable: any;
  public isLogin = false;
 
  constructor(private service:DataService)  {}
  ngOnInit() {
      // this.service.isLogin = false;
 
   
  }
 
  
  displayCounter(count) {
        console.log(count);
    }
}
