import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {APIService} from '../api.service';
import 'datatables.net';
import 'datatables.net-bs4';
import { DataService } from "../data.service";
import { OrderPipe } from 'ngx-order-pipe';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  Speclist = [];
  private SpecilityList: Array<object> = [];
  selectedLanguage = localStorage.getItem('selectedLanguage');
  checkList = [];
  // searchSpecialities;

  constructor(private apiService: APIService, public data: DataService,private router: Router, private orderPipe: OrderPipe, private userIdle: UserIdleService, private location: Location, public authService: AuthService) { }

  ngOnInit() {
    this.onLoad()
  }

  onLoad(){
        this.hideAllModals();
        this.userIdle.startWatching();
        // Start watching when user idle is starting.
        this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession()} );
        // Start watch when time is up.
        this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow()} );
        this.getSpecility()
  }

  hideAllModals(){
    $('.modal').modal('hide') // closes all active pop ups.
    $('.modal-backdrop').remove() // removes the grey overlay.
  }
expireSession(){
    $('#expireSession').modal('show')
  }
  expireSessionNow(){
    $('#expireSession').modal('hide')
  }
  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide')
  }
  logout() {
    this.logoutUser()
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        // console.log("Logout error", error);
      });
  }

  logoutUser(){
    let id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }

  getSpecility(){
    this.apiService.getSpeciality(this.selectedLanguage).subscribe((data: any) => {
      this.SpecilityList = data.data;
      var status = data.status_code;
      this.getButtonIdList()
  });
  }

  getButtonIdList(){
    var i;
    var data : any 
    data = this.SpecilityList
for (i = 0; i < data.length; i++) {
  data[i].id ;
  this.serviceCheckList(data[i].id)
 
}
  }
  buttonCheck(value, SID, CID){
    if(value.indexOf(CID) !== -1 ){
      this.apiService.SpecialityServiceEnable(SID, CID).subscribe((data: any) => {
        let status = data.status_code;
        if(status == 0){
          this.checkList = [];
          this.getSpecility()
          Swal.fire({ text: data.message, icon: 'success' });
        }
    });
    } else {
      this.apiService.SpecialityServiceDisable(SID, CID).subscribe((data: any) => {
        let status = data.status_code;
        if(status == 0){
          this.checkList = [];
          this.getSpecility()
          Swal.fire({ text: data.message, icon: 'success' });
        }
    });
    }
  }
  serviceCheckList(id){
    this.apiService.getSpecialityCheckList(id).subscribe((data: any) => {
      this.checkList.push({id :id ,result : data.results })

  });
  }

}
