import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { AgmCoreModule } from '@agm/core';
import {MapsAPILoader} from '@agm/core';
import { Observable } from 'rxjs';
import { Chart } from 'chart.js';
import {Router} from "@angular/router";
import {APIService} from '../api.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserIdleService } from 'angular-user-idle';
import { Location } from '@angular/common';
import { AuthService } from '../core/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';


@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {
    topSymptomsSearch: FormGroup;
    loadChartCustomSearch: FormGroup;
    chartSubmitted = false;
    symptomSubmitted = false;

    readonly googlePlayLink: string;
    readonly appStoreLink: string;

public cValues:any;

  constructor(private formBuilder: FormBuilder, private apiService: APIService, private router: Router, private userIdle: UserIdleService, private location: Location, public authService: AuthService) { }
  

  chartReady = true;
  labels = [];
  signups = [];
  mAppt = [];
  appt = [];
  cAppt = [];
  chart = [];
  custom = false;
  symptoms:any;
  public fromDate:any;
  public toDate:any;
  public fromDate1:any;
  public toDate1:any;
  public fromDateS:any;
  public toDateS:any;
  public statusStr="";
  public statusText="Select Booking Type";
  public bookingTypeText="All Appointments"; 
  public bookingTypeStr="";
  public totalNumber="Total";
  public missedNumber="Missed";
  public canceledNumber="Cancelled";
  public user_role:boolean;
  public days : any;
  total_appt = 0;
  total_miss=0;
  total_cancel = 0;
  total_sign = 0;
   count: any;
   chartOptions : any;
   chartData : any;
   chartLabels : any;


  addZ(n){return n<10? '0'+n:''+n;}
  ngOnInit() {
     this.hideAllModals();
    this.loadToday();
    this.userIdle.startWatching();
    
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession()} );
    
    // Start watch when time is up.
    this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow()} );

    this.topSymptomsSearch = this.formBuilder.group({
        symptomStartDate: ['', Validators.required],
        symptomEndDate: ['', Validators.required],
    });

    this.loadChartCustomSearch = this.formBuilder.group({
        chartStartDate: ['', Validators.required],
        chartEndDate: ['', Validators.required],
    });
  }

  hideAllModals(){
    $('.modal').modal('hide') // closes all active pop ups.
    $('.modal-backdrop').remove() // removes the grey overlay.
  }

  expireSession(){
    $('#expireSession').modal('show')
  }
  expireSessionNow(){
    $('#expireSession').modal('hide')
  }
  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide')
  }

  logout() {
    this.logoutUser()
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        // console.log("Logout error", error);
      });
  }

  logoutUser(){
    let id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }

    parseNum(val){
        return parseFloat(val);
    }

    get f() { return this.topSymptomsSearch.controls; }

    loadSymptoms(){
        this.symptomSubmitted = true;
        if (this.topSymptomsSearch.invalid) {
            return;
        }
      let  from = this.fromDate1.year + "-" + this.addZ(this.fromDate1.month)+"-"+this.addZ(this.fromDate1.day) + ' 00:00:00';
      let to = this.toDate1.year + "-" + this.addZ(this.toDate1.month)+"-"+this.addZ(this.toDate1.day) + ' 23:59:59';
        this.apiService.getSymptoms(from,to).subscribe((data:  any) => {
        let res = data.data;
        var sym=[];
        for(var i=0;i<res.length;i++)
        {
            sym.push(res[i].symptomName);
        }
        this.symptoms = sym.join(' » ');
        var status = data.status;
    }, error => { if(error.status == 0){this.logout()}});
    
    }
    clear(){
        this.loadToday()
        this.symptoms = []
        this.fromDate = " ";
        this.toDate = " ";
        window.location.reload()
    }
    clearAll(){
        this.symptoms = []
        this.fromDate = " ";
        this.toDate = " ";
        this.fromDateS = undefined;
         this.toDateS = undefined
    }
    loadToday(){
        this.clearAll()
        this.custom = false;
        this.days = [];
        this.days = 1;
        this.loadChart();
    }
    
    loadYesterday(){
        this.clearAll()
        this.custom = false;
        this.days = [];
        this.days = 2;
        this.loadChart();
    }
    
    loadWeek(){
        this.clearAll()
        this.custom = false;
        this.days = [];
        this.days = 3;
        this.loadChart();
    }

    public selectBookingType(id){
        this.bookingTypeStr=id;
        switch(id){
            case 1:this.bookingTypeText='Visit Appointments';
                this.totalNumber="Total Visit";
                this.missedNumber="Missed Visit";
                this.canceledNumber="Cancelled Visit";

            break;
            case 3:this.bookingTypeText='Call Appointments';
              this.totalNumber="Total Call";
              this.missedNumber="Missed Call";
              this.canceledNumber="Cancelled Call";
              break;
              case 5:this.bookingTypeText='Video Appointments';
                this.totalNumber="Total Video";
                this.missedNumber="Missed Video";
                this.canceledNumber="Cancelled Video";
            break;
            case 0:this.bookingTypeText='All Appointments';
            this.totalNumber="Total ";
            this.missedNumber="Missed ";
            this.canceledNumber="Cancelled ";
            this.bookingTypeStr="";
            break;
        }
    }

    
    
    loadCustom(){
        this.custom = true;
    }
    get f2() { return this.loadChartCustomSearch.controls; }

    loadChartCustom(){
        this.chartSubmitted = true;

        if (this.loadChartCustomSearch.invalid) {
            return;
        }

        this.fromDateS = this.fromDate.year + "-" + this.addZ(this.fromDate.month)+"-"+this.addZ(this.fromDate.day) + ' 00:00:00';
        this.toDateS = this.toDate.year + "-" + this.addZ(this.toDate.month)+"-"+this.addZ(this.toDate.day) + ' 23:59:59';
        this.days = 5;
        this.loadChart();
    }
    
    
     loadChart()  {
        this.apiService.getSignups(this.days, this.fromDateS, this.toDateS, this.bookingTypeStr).subscribe((data:  any) => {
        this.cValues = data.data;

        this.labels =[];
        this.signups =[];
        this.appt =[];
        this.mAppt = [];
        this.cAppt =[];

        this.total_appt = 0;
        this.total_miss=0;
        this.total_cancel = 0;
        this.total_sign = 0;
       for(var i=0;i<this.cValues.analyticsDetail.length;i++){
           let entry = this.cValues.analyticsDetail[i];
           this.labels.push(entry.date.replace("00:00:00"," "));
           this.mAppt.push(entry.numberOfMissedAppointments);
           this.signups.push(entry.numberOfSignups);
           this.appt.push(entry.numberOfAppointments);
           this.cAppt.push(entry.numberOfCancelledAppointment);
           
           this.total_appt += entry.numberOfAppointments;
           this.total_miss += entry.numberOfMissedAppointments;
           this.total_cancel += entry.numberOfCancelledAppointment;
           this.total_sign += entry.numberOfSignups;
       }
 
      this.chartOptions = {
        responsive: true,
        legend: {
            position: 'bottom',
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Date'
                },
            }],
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Number',
                },
            }]
        },
        title: {
            display: true,
            text: 'CrossBorderTeleHealth Chart'
        }
      };
      this.chartData = [
        {
            label: "Total Appointments",
            data: this.appt,
            fill: false,
            borderDash: [5, 5],
            borderColor: "#5ed84f",
            pointBorderColor: "#5ed84f",
            pointBackgroundColor: "#5ed84f",
        }, {
            label: "Missed Appointments",
            data: this.mAppt,
            fill: false,
            borderDash: [5, 5],
            borderColor: "#ffc107",
            pointBorderColor: "#ffc107",
            pointBackgroundColor: "#ffc107",
        },
            {
                label: "Cancelled Appointment",
                data: this.cAppt,
                fill: false,
                borderDash: [5, 5],
                borderColor: "#fa626b",
                pointBorderColor: "#fa626b",
                pointBackgroundColor: "#fa626b",
            },
            {
            label: "Signups",
            data: this.signups,
            fill: false,
            borderDash: [5, 5],
            borderColor: "#28afd0",
            pointBorderColor: "#28afd0",
            pointBackgroundColor: "#28afd0",
        }
      ];
      this.chartLabels = this.labels;
     
        }, error => { if(error.status == 0){this.logout()}});
        
    }

    
    






}

