import {Component, OnInit } from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {ValidationService} from '../validation.service';

@Component({
  selector: 'app-user-package',
  templateUrl: './user-package.component.html',
  styleUrls: ['./user-package.component.css']
})

export class UserPackageComponent implements OnInit {

  newUserPackageForm: FormGroup;
  editUserPackageForm: FormGroup;
  addSpecialityForm: FormGroup;
  deleteSpecialityServiceForm: FormGroup;

  submitted = false;
  public userPackages: Array<object> = [];
  private newUserPackages = {};
  private editUserPackage = {};
  private specialityList: Array<object> = [];
  dataArr: any[];
  packageSearch;
  minDate: {year: number, month: number, day: number};


  constructor(private validationService: ValidationService, private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) {

    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };

  }


  ngOnInit() {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession(); } );

    // Start watch when time is up.
    this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow(); } );

    this.getUserPackages();

    this.newUserPackageForm = this.formBuilder.group({
      package_name: ['', [Validators.required]],
    //  slot_count: ['', [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$") ]],
      amount: ['', [Validators.required ]],
      valid_from: ['', [Validators.required]],
      valid_to: ['', [Validators.required]],
      is_active: (''),
      is_special: (''),
      promo_code: '',
    });

    this.editUserPackageForm = this.formBuilder.group({
      package_name: ['', Validators.required],
    //  slot_count: ['', [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$") ]],
      amount: ['', [Validators.required]],
      is_active: '',
      is_special: '',
      valid_from: ['', [Validators.required]],
      valid_to: ['', [Validators.required]],
      package_id : '',
      promo_code: '',
    });

    this.addSpecialityForm = this.formBuilder.group({
      speciality_id: ['', Validators.required],
      slot_count: ['', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$') ]],
      booking_type_id: ['', Validators.required],
      package_id : (''),
    });

    this.deleteSpecialityServiceForm = this.formBuilder.group({
      speciality_id: ['', Validators.required],
      slot_count: ['', [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$') ]],
      booking_type_id: ['', Validators.required],
      package_id : '',
    });

    this.apiService.getSpeciality('EN').subscribe((data: any) => {
      this.specialityList = data.data;
      const status = data.status;
    }, error => { if (error.status == 0) {this.logout(); }});
  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show');
  }

  expireSessionNow() {
    $('#expireSession').modal('hide');
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
  }

  logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
        .then((res) => {
          this.location.back();
        }, (error) => {
        });
  }


  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
    });
  }

  get f1() { return this.newUserPackageForm.controls; }
  get f2() { return this.editUserPackageForm.controls; }
  get f3() { return this.addSpecialityForm.controls; }



  public getUserPackages() {
    this.apiService.getUserPackages(1).subscribe((data: any) => {
      this.userPackages = data.data;
      // var status = data.status_code;
    }, error => {});
  }


  onSubmit() {

    const a = new Date(this.newUserPackageForm.value.valid_from.year + '-' + this.newUserPackageForm.value.valid_from.month + '-' + this.newUserPackageForm.value.valid_from.day + ' 00:00:00');
    const b = new Date(this.newUserPackageForm.value.valid_to.year + '-' + this.newUserPackageForm.value.valid_to.month + '-' + this.newUserPackageForm.value.valid_to.day + ' 23:59:59');

    if ( a > b) {
      Swal.fire({text: 'Valid to date should be greater than valid from date', icon: 'error'});
      return;
    }

    this.submitted = true;

    if (this.newUserPackageForm.invalid) {
      return;
    }

    const data = {
      package_name: this.newUserPackageForm.value.package_name,
    //  slot_count: this.newUserPackageForm.value.slot_count,
      amount: this.newUserPackageForm.value.amount,
      is_active: this.newUserPackageForm.value.is_active === true ? 1 : 0,
      is_special: this.newUserPackageForm.value.is_special === true ? 1 : 0,
      valid_from: this.newUserPackageForm.value.valid_from.year + '-' + this.newUserPackageForm.value.valid_from.month + '-' + this.newUserPackageForm.value.valid_from.day + ' 00:00:00',
      valid_to: this.newUserPackageForm.value.valid_to.year + '-' + this.newUserPackageForm.value.valid_to.month + '-' + this.newUserPackageForm.value.valid_to.day + ' 23:59:59',
      promo_code: this.newUserPackageForm.value.promo_code,

    };

    this.apiService.addUserPackage(data).subscribe((data: any) => {
      this.submitted = true;
      const status = data.status_code;
      if (status == 0) {
        this.getUserPackages();
        Swal.fire({ text: data.message, icon: 'success' });
        this.newUserPackageForm.reset();
        $('#newUserPackage').modal('hide');
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if (error.status == 0) {$('#newUserPackage').modal('hide'); this.logout(); }});

  }


  public editUserPackages(editUserPackage) {


    const a = new Date(this.editUserPackageForm.value.valid_from.year + '-' + this.editUserPackageForm.value.valid_from.month + '-' + this.editUserPackageForm.value.valid_from.day + ' 00:00:00');
    const b = new Date(this.editUserPackageForm.value.valid_to.year + '-' + this.editUserPackageForm.value.valid_to.month + '-' + this.editUserPackageForm.value.valid_to.day + ' 23:59:59');
    const x = new Date(this.editUserPackage['valid_from']);
    const y = new Date(this.editUserPackage['valid_to']);
    const z = new Date();

    if (!(+a === +x)) {
      if (a.setHours(0, 0, 0, 0) < z.setHours(0, 0, 0, 0) ) {
        Swal.fire({text: 'Valid from date should not be a before date than the current date', icon: 'error'});
        return;
      }
    }

    if (!(+b === +y)) {
      if (b < z ) {
        Swal.fire({text: 'Valid to date should not be a before date than the current date', icon: 'error'});
        return;
      }
    }

    if ( a > b) {
      Swal.fire({text: 'Valid to date should be greater than valid from date', icon: 'error'});
      return;
    }

    this.submitted = true;

    if (this.editUserPackageForm.invalid) {

      console.log(this.editUserPackageForm);
      return;
    }

    const editData = {
      package_id: this.editUserPackageForm.value.package_id,
      package_name: this.editUserPackageForm.value.package_name,
     // slot_count: this.editUserPackageForm.value.slot_count,
      amount: this.editUserPackageForm.value.amount,
      valid_from: this.editUserPackageForm.value.valid_from.year + '-' + this.editUserPackageForm.value.valid_from.month + '-' + this.editUserPackageForm.value.valid_from.day + ' 00:00:00',
      valid_to: this.editUserPackageForm.value.valid_to.year + '-' + this.editUserPackageForm.value.valid_to.month + '-' + this.editUserPackageForm.value.valid_to.day + ' 23:59:59',
      is_active: this.editUserPackageForm.value.is_active === true ? 1 : 0,
      is_special: this.editUserPackageForm.value.is_special === true ? 1 : 0,
      promo_code: this.editUserPackageForm.value.promo_code,

    };

    this.apiService.editUserPackages(editData).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.getUserPackages();
        Swal.fire({ text: data.message, icon: 'success' });
        this.editUserPackageForm.reset();
        $('#editUserPackage').modal('hide');
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, );
  }

  public addSpeciality(package_id) {

    this.submitted = true;

    if (this.addSpecialityForm.invalid) {
      return;
    }

    const specialties = {
      package_id: package_id,
      speciality_id: this.addSpecialityForm.value.speciality_id,
      slot_count: this.addSpecialityForm.value.slot_count,
      booking_type_id: this.addSpecialityForm.value.booking_type_id,
      is_active : 1
    };

    this.apiService.addUserPackageSpecialities(specialties).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.addSpecialityForm.reset();
        this.showUserPackage(specialties.package_id);
        Swal.fire({text: data.message, icon: 'success'});
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, );
  }


  public deleteUserPackageSpecialitiesServices(userPackageId, specialityId, bookingTypeId) {

  //  this.submitted = true;

    const data = {
      package_id: userPackageId,
      speciality_id: specialityId,
      booking_type_id: bookingTypeId,
    };

    this.apiService.deleteUserPackageSpecialities(data).subscribe((data: any) => {
   //   this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.deleteSpecialityServiceForm.reset();
        this.showUserPackage(userPackageId);
        Swal.fire({text: data.message, icon: 'success'});
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, );
  }

  public showUserPackage(packageId) {
    this.apiService.getPackageById(packageId).subscribe((data: any) => {
      const status = data.status_code;
      const valid_from_date = new Date(data.data.valid_from);
      const valid_to_date = new Date(data.data.valid_to);

      data.data.valid_from_array = {
        year: valid_from_date.getFullYear(),
        month: valid_from_date.getMonth() + 1,
        day: valid_from_date.getDate(),
      };

      data.data.valid_to_array = {
        year: valid_to_date.getFullYear(),
        month: valid_to_date.getMonth() + 1 ,
        day: valid_to_date.getDate(),
      };

      this.editUserPackage = data.data;
      this.editUserPackageForm.setValue({
        'package_name':  this.editUserPackage['package_name'],
       // 'slot_count': this.editUserPackage['slot_count'] ,
        'amount': this.editUserPackage['amount'],
        'is_active' : this.editUserPackage['is_active'] === 0 ? false : true,
        'is_special' : this.editUserPackage['user_package_type'] === 0 ? false : true,
        'valid_from' : data.data.valid_from_array ,
        'valid_to' : data.data.valid_to_array ,
        'package_id' : packageId,
        'promo_code' : this.editUserPackage['promo_code']
      });

      this.dataArr = [];
      if (data.data.userPackageSpecialities.length > 0) {
       data.data.userPackageSpecialities.map((sPackage: any) => {
          sPackage.serviceTypes.map((service: any) => {
            const a = {
              specialityName: sPackage.speciality_name_en,
              typeEn: service.type_en,
              slotCount: service.slot_count,
              remainingSlotCount : service.slot_count - service.used_slot_count,
              specialityId : sPackage.speciality_id,
              bookingTypeId : service.id,
              packageId: packageId
            };
            this.dataArr.push(a);
          });
        });
      }
    }, error => { if (error.status == 0) {$('#editUserPackage').modal('hide'); this.logout(); }} );
  }

  public StartTime(data) {
    const responseTime = moment(data).format('YYYY-MM-DD');
    return responseTime;
  }

  public EndTime(data) {
    const responseTime = moment(data).format('YYYY-MM-DD');
    return responseTime;
  }


  public changeStatus(data) {
    this.apiService.changeStatusUserPackage(data).subscribe((data: any) => {
      const status = data.status_code;
      if (status == 0) {
        this.getUserPackages();
        Swal.fire({ text: data.message, icon: 'success' });
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if (error.status_code == 1) {this.logout(); }});
  }

}
