import { Component, OnInit } from '@angular/core';

import { DataService } from "../data.service";

import { AuthService } from '../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APIService } from '../api.service';
@Component({
    selector: 'app-login-control',
    templateUrl: './login-control.component.html',
    styleUrls: ['./login-control.component.css']
})
export class LoginControlComponent implements OnInit {

    username = ""
    password = ""
    ForgotPasswordVal: any = {};
    loginForm: FormGroup;
    errorMessage: string = '';
    submitted = false;
    userData: any;
    userRoles: any = []; 
    public loginError= 0;

    forgotPasswordForm: FormGroup;
    submitted2 = false;

    constructor(private apiService: APIService, public authService: AuthService,
        private router: Router,
        private formBuilder: FormBuilder) { this.createForm(); }


    createForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    get f2() { return this.loginForm.controls; }
    onLogin() {
        // this.tryLogin(loginForm.value)
        this.tryLogin({
            email: this.loginForm.value.email,
            password: this.loginForm.value.password,
        })
    }
    tryLogin(value) {
        // this.createForm() 
        this.submitted2 = true;
        if (this.loginForm.invalid) {
            return;
        } else { 
            this.authService.doLogin(value)
            .then(data => {
                this.userData = data.data;
                if( this.userData.userRoleId == 3 || this.userData.userRoleId == 4 ){
                    localStorage.setItem("user_role","1")
                    localStorage.setItem("userId",  this.userData.id)
                    localStorage.setItem("authToken",  this.userData.authToken)
                    localStorage.setItem('selectedLanguage', 'en');
                    this.router.navigate(['/portal/stats']);
                    this.loginError = 0;
                }else{
                    alert("user is not an admin!");
                    this.loginError = 1;
                }
            }, error => {
                this.loginError = 1;
                // console.log(error);
                this.errorMessage = error.message;
            })
        }

    }

    ngOnInit() {
        this.hideAllModals()
        $('#expireSession').modal('hide');
        setTimeout(
            function()
            {
            }, 5000);


        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });   
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }
    hideAllModals(){
        $('.modal').modal('hide') // closes all active pop ups.
        $('.modal-backdrop').remove() // removes the grey overlay.
      }

    get f() { return this.forgotPasswordForm.controls; }
    ForgotPassword() {
        this.submitted = true;

        var data = {
            typeId: 1,
            userId: 0,
            email:this.forgotPasswordForm.value.email,
        }
        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        } else {
            this.apiService.ForgotPasswordByEmail(data).subscribe((data: any) => {
                var status = data.status;
                // console.log(status);
                if(status.status == "SUCCESS"){
                    alert("Password reset successfully");
                    $("#exampleModal").modal('hide');
                    window.location.reload();
              
                }
                else{
                    alert(status.reason);
                }
            });

        }

    }
}
