import {Component, OnInit } from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';
import {ValidationService} from '../validation.service';

@Component({
  selector: 'app-video-records',
  templateUrl: './video-records.component.html',
  styleUrls: ['./video-records.component.css']
})
export class VideoRecordsComponent implements OnInit {

  public records: Array<object> = [];


  recordsSearch;
  selectedLanguage = localStorage.getItem('selectedLanguage');


  constructor(private validationService : ValidationService, private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) { }

  ngOnInit() {
    this.getRecordingsDetails();
  }



  public getRecordingsDetails() {
    this.apiService.getRecordings(this.selectedLanguage).subscribe((data: any) => {
      this.records = data.data;
    }, error => {});
  }

  public showAppointmentRecord(){

  }

}


