import { Component, OnInit, AfterViewInit } from '@angular/core';
import { APIService } from '../api.service';
import { DataService } from "../data.service";
import { LoginControlComponent } from '../login-control/login-control.component';
import { UserIdleService } from 'angular-user-idle';
import { Location } from '@angular/common';
import { AuthService } from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


import { Routes, RouterModule, Router } from '@angular/router';
@Component({
  selector: 'app-fare-list',
  templateUrl: './fare-list.component.html',
  styleUrls: ['./fare-list.component.css']
})
export class FareListComponent implements OnInit {


  private dayTimeAmount = 0;
  private nightTimeAmount = 0;
  private dayTimeAmountVisit = 0;
  private nightTimeAmountVisit = 0;
  private loadCommission = 0;
  private isCommission = false;
  private isShowDayTime = false;
  private isShowNightTime = false;
  private isShowDayTimeVisit = false;
  private isShowNightTimeVisit = false;
  private isShowDayTimeVideo = false;
  private isShowNightTimeVideo = false;
  public user_role: boolean;
  public username: string;
  public patientId = "";
  private dayTimeAmountVideo = 0;
  private NightTimeVideoAmount = 0;
  private fareData: Array<object> = [];
  public updateFareDataList: any;
  public specialityTitle:any;
  public fareType:any;
  public fareTypeId:any;
  public fareUpdateSuccess = 0;
  public fareUpdateFail = 0;
  public bookingTypeId = 0;
  public   selectedLanguage = localStorage.getItem('selectedLanguage');


  constructor(private apiService: APIService, public data: DataService, public router: Router, private userIdle: UserIdleService, private location: Location, public authService: AuthService) { }

  ngOnInit() {
    this.hideAllModals();
    this.apiService.fareList(localStorage.getItem('selectedLanguage')).subscribe((data: any) => {
      this.fareData = data.data.specialityFareTypes;
      var status = data.status_code;
    }, error => { if(error.status == 0){this.logout()}});
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => { console.log(count), this.expireSession() });
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => { this.logout(), this.expireSessionNow() });
  }
  hideAllModals(){
    $('.modal').modal('hide') // closes all active pop ups.
    $('.modal-backdrop').remove() // removes the grey overlay.
  }
  expireSession() {
    $('#expireSession').modal('show')
  }
  expireSessionNow() {
    $('#expireSession').modal('hide')
  }
  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide')
  }
  logout() {
    this.logoutUser()
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        // console.log("Logout error", error);
      });
  }

  logoutUser(){
    let id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }
  updateFare(value, title, type, typeId, fareTypeId) {
    $('.fare-update-modal').modal('show');
    this.updateFareDataList = value;
    this.specialityTitle = title;
    this.fareType = type;
    this.bookingTypeId = typeId;
    this.fareTypeId = fareTypeId;
  }
  updateFareSubmit(faredata) {
    for (let [key, value] of Object.entries(faredata)) {
      // console.log(`${key}: ${value}`);
      if (value !== '') {
        var data = {
          amount: value,
          id: key,
          modifiedBy: localStorage.getItem('userId')
        }
        this.apiService.fareListUpdate(data).subscribe((data: any) => {
          var status = data.status_code;
          // console.log(status);
        if (status == 0) {
          Swal.fire({ text: data.message, icon: 'success' });
          $('.fare-update-modal').modal('hide')
          this.fareUpdateSuccess = 1;
          setTimeout(() => {
            this.fareUpdateSuccess = 0;
          }, 9000);

          }
          else {
            alert(status.reason);
            $('.fare-update-modal').modal('hide')
            this.fareUpdateFail = 1;
            setTimeout(() => {
              this.fareUpdateFail = 0;
            }, 9000);
          }
        }, error => { if(error.status == 0){ $('.fare-update-modal').modal('hide'); this.logout()}});

      }

    }
  }

}
