import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../data.service';
import { APIService } from '../api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Location } from '@angular/common';
import { AuthService } from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-appointment-settings',
  templateUrl: './appointment-settings.component.html',
  styleUrls: ['./appointment-settings.component.css']
})
export class AppointmentSettingsComponent implements OnInit {
  @ViewChild('doctorAutocomplete' ) doctorAutocomplete;
  @ViewChild('specialityAutocomplete' ) specialityAutocomplete;
  private activeDoctorlist: Array<object> = [];
  activeDocters = 'first_name';
  speciality = 'name';
  selectedLanguage = localStorage.getItem('selectedLanguage');
  private specialityList: Array<object> = [];
  private timeSlots: Array<object> = [];
  public timeSlotData: Array<object> = [];
  requestedTo: any;
  startTime: any;
  endTime: any;
  selectedDoctor: any;
  selectedSpecility: any;
  searchByDoctor;
  appointmentFilter = false;
  showAddBox = false;
  allowForAll = [];

  constructor(public data: DataService, private apiService: APIService, protected sanitizer: DomSanitizer, private router: Router, private userIdle: UserIdleService, private location: Location, public authService: AuthService) { }

  ngOnInit() {
    this.hideAllModals();
    this.Speciality();
            // Start watching when user idle is starting.
            this.userIdle.onTimerStart().subscribe((count) => { console.log(count), this.expireSession(); });
            // Start watch when time is up.
            this.userIdle.onTimeout().subscribe(() => { this.logout(), this.expireSessionNow(); });
            this.timeList();


  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }


  expireSession() {
    $('#expireSession').modal('show');
}
expireSessionNow() {
    $('#expireSession').modal('hide');
}
Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
}
logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        // console.log("Logout error", error);
      });
  }

  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }

  public Speciality() {
    this.apiService.getSpeciality(this.selectedLanguage).subscribe((data: any) => {
      this.specialityList = data.data;
  });
  }

  doctorsBySpecility(id) {
    this.apiService.getDoctorsBySpecialty(id).subscribe((data: any) => {
      const status = data.status_code;
      if (status === '0') {
        this.activeDoctorlist = data.data;
        this.activeDoctorlist.map(country => country['first_name'] = `${country['first_name']} ${country['last_name']}`);
      }

  });
  }

  selectDoc(event) {
    this.selectedDoctor = event;

  }

  filterDocBase() {
    this.tableDataLoad();
  }

  selectSpecility(event) {
    this.selectedSpecility = event;
    this.doctorsBySpecility(event.id);
  }

  deleteAlert(data) {
    Swal.fire({
      text: 'Are you sure you want to delete the Time Slot?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {      // Save it!
        this.deleteTimeslot(data);
      }
    })
  }

  addZ(n) { return n < 10 ? '0' + n : '' + n; }
  filterAdd() {
   const selectedDate = this.requestedTo.year + '-' + this.addZ(this.requestedTo.month) + '-' + this.addZ(this.requestedTo.day);

    if (!(this.selectedSpecility.id > 0)) {
      Swal.fire({text: 'Please select a Speciality', icon: 'warning'});
      return;
    }


    // if (!(this.selectedDoctor.id > 0) || this.selectedDoctor.id === '' ) {
    //   alert('please select a Doctor');
    //   return;
    // }

   if (this.startTime >= this.endTime) {
     Swal.fire({text: 'Start time should be less than the end time', icon: 'warning'});
     return;
   }

    const data = {
      docId: this.selectedDoctor.id,
      date: selectedDate,
      STime: this.startTime + ':00',
      ETime: this.endTime + ':00',
      addAll: this.allowForAll[0] || 0,
      specility: this.selectedSpecility.id,
  };
  this.apiService.AppointmentSettingstimeslots(data).subscribe((data: any) => {
      const status = data.status_code;
      if (status == '0') {
        this.tableDataLoad();
        this.showAddBox = false;
        Swal.fire({text: 'Timeslot added successfully', icon: 'success'});
      } else if (status == '1') {
        Swal.fire({text: 'Timeslot is overlapping with another timeslot', icon: 'error'});
      }
  });
  }
  FilterDateChange(newValue) {
    this.requestedTo = newValue;

  }

  showAdd() {
    this.showAddBox = !this.showAddBox;
  }

  filterSpecial() {
    if (this.selectedSpecility && this.requestedTo) {
      this.timeSlotData = [];
      const selectedDate = this.requestedTo.year + '-' + this.addZ(this.requestedTo.month) + '-' + this.addZ(this.requestedTo.day);
      const data = {
        speciality: this.selectedSpecility.id,
        docId: 0,
        date: selectedDate,
    };
    this.apiService.AppointmentSettingsTabledata(data).subscribe((data_2: any) => {
      console.log(data_2.result);
      this.timeSlotData = data_2.result;
        const status = data_2.status_code;
        if (status == '1') {
          this.appointmentFilter = true;

         // alert("Timeslots not available")
        } else {
          this.appointmentFilter = true;
        }
    });
    } else {
      Swal.fire({text: 'Please select a speciality and a Date', icon: 'warning'});
    }
  }

  tableDataLoad() {

    if (this.selectedDoctor && this.requestedTo) {
      this.timeSlotData = [];
      const selectedDate = this.requestedTo.year + '-' + this.addZ(this.requestedTo.month) + '-' + this.addZ(this.requestedTo.day);
      const data1 = {
        speciality: this.selectedSpecility.id,
        docId: this.selectedDoctor.id,
        date: selectedDate,
    };
    this.apiService.AppointmentSettingsTabledata(data1).subscribe((data_1: any) => {
      this.timeSlotData = data_1.result;
        const status = data_1.status_code;
        if (status == '1') {
          Swal.fire({text: 'Timeslots not available', icon: 'error'});
        }
    });
    }

  }

  deleteTimeslot(value) {
    const data = {
      docId: value.id,
  };
    this.apiService.AppointmentSettingsDelete(data).subscribe((data: any) => {
        const status = data.status_code;
        if (status == '0') {
          Swal.fire({text: 'Timeslot deleted successfully', icon: 'success'});
          this.timeSlotData = [];
        } else  {
          Swal.fire({text: 'Timeslot fail to delete', icon: 'error'});
        }
    });
  }

  AppointmentEdit() {
    $('#exampleModal').modal('show');
  }


  public timeList() {
    this.timeSlots = [
      {  time: '00:00' },
      {  time: '00:15' },
      {  time: '00:30' },
      {  time: '00:45' },
      {  time: '01:00' },
      {  time: '01:15' },
      {  time: '01:30' },
      {  time: '01:45' },
      {  time: '02:00' },
      {  time: '02:15' },
      {  time: '02:30' },
      {  time: '02:45' },
      {  time: '03:00' },
      {  time: '03:15' },
      {  time: '03:30' },
      {  time: '03:45' },
      {  time: '04:00' },
      {  time: '04:15' },
      {  time: '04:30' },
      {  time: '04:45' },
      {  time: '05:00' },
      {  time: '05:15' },
      {  time: '05:30' },
      {  time: '05:45' },
      {  time: '06:00' },
      {  time: '06:15' },
      {  time: '06:30' },
      {  time: '06:45' },
      {  time: '07:00' },
      {  time: '07:15' },
      {  time: '07:30' },
      {  time: '07:45' },
      {  time: '08:00' },
      {  time: '08:15' },
      {  time: '08:30' },
      {  time: '08:45' },
      {  time: '09:00' },
      {  time: '09:15' },
      {  time: '09:30' },
      {  time: '09:45' },
      {  time: '10:00' },
      {  time: '10:15' },
      {  time: '10:30' },
      {  time: '10:45' },
      {  time: '11:00' },
      {  time: '11:15' },
      {  time: '11:30' },
      {  time: '11:45' },
      {  time: '12:00' },
      {  time: '12:15' },
      {  time: '12:30' },
      {  time: '12:45' },
      {  time: '13:00' },
      {  time: '13:15' },
      {  time: '13:30' },
      {  time: '13:45' },
      {  time: '14:00' },
      {  time: '14:15' },
      {  time: '14:30' },
      {  time: '14:45' },
      {  time: '15:00' },
      {  time: '15:15' },
      {  time: '15:30' },
      {  time: '15:45' },
      {  time: '16:00' },
      {  time: '16:15' },
      {  time: '16:30' },
      {  time: '16:45' },
      {  time: '17:00' },
      {  time: '17:15' },
      {  time: '17:30' },
      {  time: '17:45' },
      {  time: '18:00' },
      {  time: '18:15' },
      {  time: '18:30' },
      {  time: '18:45' },
      {  time: '19:00' },
      {  time: '19:15' },
      {  time: '19:30' },
      {  time: '19:45' },
      {  time: '20:00' },
      {  time: '20:15' },
      {  time: '20:30' },
      {  time: '20:45' },
      {  time: '21:00' },
      {  time: '21:15' },
      {  time: '21:30' },
      {  time: '21:45' },
      {  time: '22:00' },
      {  time: '22:15' },
      {  time: '22:30' },
      {  time: '22:45' },
      {  time: '23:00' },
      {  time: '23:15' },
      {  time: '23:30' },
      {  time: '23:45' }
    ];
  }

}
