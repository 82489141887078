import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DataService} from '../data.service';
import {APIService} from '../api.service';
import 'datatables.net';
import 'datatables.net-bs4';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import {DatePipe} from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
    selector: 'app-appointment-list',
    templateUrl: './appointment-list.component.html',
    styleUrls: ['./appointment-list.component.css']
})
export class AppointmentListComponent implements OnInit, AfterViewInit {
    @ViewChild('patientAutocomplete') patientAutocomplete;
    @ViewChild('doctorAutocomplete') doctorAutocomplete;

    private appointments: Array<object> = [];

    public page = '';
    public docId = '';
    public patientId = '';
    public statusStr = '';
    public bookingTypeStr = '';
    public specialityID = '';
    public providerType = '';
    public specialityOrProviderType :any;
    public specialityOrProviderTypeName :any;
    public selectedAppointmentData :any;
    public requestedFrom: any;
    public requestedTo: any;
    public availabilityDate: any;
    public timeslotId: any;
    public timeSlotData: any;
    public allocatedStartTime: any;
    public allocatedEndTime: any;
    public allocatedStartTimeOnchange: any;
    public allocatedEndTimeOnchange: string;
    public assignDocSelectedID: any;
    public supportDoctorId :any;
    public supportDoctorDetails :any;
    public recordDetails :any;
    public total = 0;
    public appointmentId = '';
    dataTable: any;
    public size = '';
    public specialitySelect = 'Select a Specialty';
    public statusText = 'Select a Status';
    public bookingTypeText = 'All Appointments';
    public docTableShow = 0;
    public user_role: boolean;
    private assignDocDataList: Array<object> = [];
    private timeSlotlit: Array<object> = [];
    private assingDocTable: Array<object> = [];
    private specialityList: Array<object> = [];
    private providerTypeList: Array<object> = [];
    public selectedTableRow: any;
    public forceUpdate = false;
    forceUpdateShow = 0;
    forceUpdateTickCheck = [];
    modifyAssignDoctorValidation = 0;
    modifyAvailableDoctorValidation = 0;
    appointmentStartTimeEmpty = 0;
    inbetweenTime = 0;
    SelectValue = 0;
    searchReference;
    private patientList: Array<object> = [];
    private activeDoctorlist: Array<object> = [];
    selectedLanguage = localStorage.getItem('selectedLanguage');
    selectedAppointmentId : any;
    activeDocters = 'name' || 'speciality';
    patients = 'name' || 'email';

    constructor(private datePipe: DatePipe, public data: DataService,private route: ActivatedRoute, private apiService: APIService, protected sanitizer: DomSanitizer, private router: Router, private userIdle: UserIdleService, private location: Location, public authService: AuthService) {

    }

    ngOnInit() {
        this.hideAllModals();
        this.apiService.getSpeciality(this.selectedLanguage).subscribe((data: any) => {
            this.specialityList = data.data;
        });
        this.apiService.getProviderTypes(this.selectedLanguage).subscribe((data: any) => {
            this.providerTypeList = data.data;
        });
        this.getDoctors();
        this.getPatients();
        this.onloadAppoinment();
        this.userIdle.startWatching();
        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe((count) => {
            console.log(count), this.expireSession();
        });
        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {
            this.logout(), this.expireSessionNow();
        });
    }

    hideAllModals() {
        $('.modal').modal('hide'); // closes all active pop ups.
        $('.modal-backdrop').remove(); // removes the grey overlay.
    }

    public getDoctors() {
        this.apiService.getDoctors(this.selectedLanguage).subscribe((data: any) => {
            this.activeDoctorlist = data.data;
        }, error => {
            if (error.status == 0) {
                this.logout();
            }
        });
    }

    public getPatients() {
        this.apiService.getPatients().subscribe((data: any) => {
            this.patientList = data.data;

        }, error => {
            if (error.status == 0) {
                this.logout();
            }
        });
    }

    expireSession() {
        $('#expireSession').modal('show');
    }

    expireSessionNow() {
        $('#expireSession').modal('hide');
    }

    Sessionrestart() {
        this.userIdle.resetTimer();
        $('#expireSession').modal('hide');
    }

    logout() {
        this.logoutUser();
        localStorage.clear();
        this.authService.doLogout()
            .then((res) => {
                this.location.back();
            }, (error) => {
                // console.log("Logout error", error);
            });
    }

    logoutUser() {
        let id = localStorage.getItem('userId');
        this.apiService.logoutUser(id).subscribe((data: any) => {
        });
    }

    assignDoc(value) {
        this.forceUpdate = false;
        this.modifyAvailableDoctorValidation = 0;
        this.allocatedStartTimeOnchange = '';
        this.allocatedEndTimeOnchange = ' ';
        this.selectedTableRow = null;
        this.forceUpdateTickCheck = [];
        this.forceUpdateShow = 0;
        this.allocatedStartTime = '';
        this.allocatedEndTime = ' ';
        this.assignDocSelectedID = undefined;
        this.allocatedStartTime = undefined;
        this.timeSlotData = undefined;
        $('#assignDoctorModal').modal('show');
        this.assignDocDataList = value;
        this.availabilityDate = value.appointmentDate;
        this.timeslotId = value.timeslotId;
        this.apiService.assingDocTimeSlots().subscribe((data: any) => {
            this.timeSlotlit = data.data;
            var status = data.status_code;
        }, error => {
            if (error.status == 0) {
                $('#assignDoctorModal').modal('hide');
                this.logout();
            }
        });
        this.listDoctors(value);
    }


    showSupportDoctorDetails(value) {
        this.supportDoctorDetails = value;
        $('#supportDoctorDetailModal').modal('show');
    }

  showRecordDetails(value) {
        this.recordDetails = value;
        $('#recordDetailsModal').modal('show');
    }

    /**
     * assign provider type
     * @param value
     */
    assignProviderType(value) {
        this.selectedAppointmentData = value;
        $('#providerTypeSelection').modal('show');
    }

    playMedia(value) {
        let absolute;
        var url = value;

        console.log(url);

        Swal.fire({
            videoUrl: url,
            didOpen: () => {
                // @ts-ignore
                video.src=url;
            },
            html: '<iframe id="video" width="500px" height="500px" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
            //  imageWidth: '200px',
            //  imageHeight: '200px',
            imageAlt: 'Ticket Attachments',
            imageBorderRadius: '10%',
            width: '600px',
            allowOutsideClick: true
        });
    }


    assignProvider(value,providerType) {

        this.forceUpdate = false;
        this.modifyAvailableDoctorValidation = 0;
        this.allocatedStartTimeOnchange = '';
        this.allocatedEndTimeOnchange = ' ';
        this.selectedTableRow = null;
        this.forceUpdateTickCheck = [];
        this.forceUpdateShow = 0;
        this.allocatedStartTime = '';
        this.allocatedEndTime = ' ';
        this.assignDocSelectedID = undefined;
        this.allocatedStartTime = undefined;
        this.timeSlotData = undefined;
        $('#providerTypeSelection').modal('hide');
        $('#assignProviderModal').modal('show');
        this.assignDocDataList = this.selectedAppointmentData;
        this.availabilityDate = this.selectedAppointmentData.appointmentDate;
        this.timeslotId = this.selectedAppointmentData.timeslotId;
        this.apiService.assingDocTimeSlots().subscribe((data: any) => {
            this.timeSlotlit = data.data;
            var status = data.status_code;
        }, error => {
            if (error.status == 0) {
                $('#providerTypeSelection').modal('hide');
                this.logout();
            }
        });

        this.providerType  = providerType;
        if( this.providerType =='1' ){
            this.specialityOrProviderType  = value.id;
            this.specialityOrProviderTypeName  = value.provider_type_name;
            this.listProviders(this.assignDocDataList,value.id)
        }else{
            this.specialityOrProviderType  = value.id;
            this.specialityOrProviderTypeName  = value.name;
            this.listDoctorsAsProviders(this.assignDocDataList,value.id);

        }

    }

    selectSpeciality(value) {
        this.specialityID = value.id;
        this.specialitySelect = value.name;
    }


    listDoctors(value) {
        if (this.availabilityDate.year) {
            var date = '';
            if (this.availabilityDate != '') {
                var removeTime2 = this.availabilityDate.year + '-' + this.addZ(this.availabilityDate.month) + '-' + this.addZ(this.availabilityDate.day);
            }
            date = this.datePipe.transform(removeTime2, 'yyyy-MM-dd');
            // this.availabilityDate = date;
        } else {
            var removeTime = this.availabilityDate;
            date = this.datePipe.transform(removeTime, 'yyyy-MM-dd');
        }

        let data = {
            speciality_id: value.specialityId,
            appointment_date: date,
            timeslot_id: this.timeslotId,
            duration: value.duration,
            slot_time: date +' '+value.preferredTime

        };
        this.apiService.assingDocList(data).subscribe((data: any) => {
            // this.assingDocTable = data.data.appointmentEligibleDoctors;
            this.assingDocTable = data.results;
            var status = data.status;
        }, error => {
            if (error.status == 0) {
                $('#assignDoctorModal').modal('hide');
                this.logout();
            }
        });
    }

    listDoctorsAsProviders(value,specility_id) {
        if (this.availabilityDate.year) {
            var date = '';
            if (this.availabilityDate != '') {
                var removeTime2 = this.availabilityDate.year + '-' + this.addZ(this.availabilityDate.month) + '-' + this.addZ(this.availabilityDate.day);
            }
            date = this.datePipe.transform(removeTime2, 'yyyy-MM-dd');
            // this.availabilityDate = date;
        } else {
            var removeTime = this.availabilityDate;
            date = this.datePipe.transform(removeTime, 'yyyy-MM-dd');
        }

        let data = {
            speciality_id: specility_id,
            appointment_date: date,
            timeslot_id: this.timeslotId,
            duration: value.duration,
            slot_time: date +' '+value.preferredTime

        };
        this.apiService.assingDocList(data).subscribe((data: any) => {
            // this.assingDocTable = data.data.appointmentEligibleDoctors;
            this.assingDocTable = data.data;
            var status = data.status_code;
        }, error => {
            if (error.status == 0) {
                $('#assignDoctorModal').modal('hide');
                this.logout();
            }
        });
    }

    listProviders(value,provider_type_id) {
        if (this.availabilityDate.year) {
            var date = '';
            if (this.availabilityDate != '') {
                var removeTime2 = this.availabilityDate.year + '-' + this.addZ(this.availabilityDate.month) + '-' + this.addZ(this.availabilityDate.day);
            }
            date = this.datePipe.transform(removeTime2, 'yyyy-MM-dd');
            // this.availabilityDate = date;
        } else {
            var removeTime = this.availabilityDate;
            date = this.datePipe.transform(removeTime, 'yyyy-MM-dd');
        }

        let data = {
            provider_type_id: provider_type_id,
            appointment_date: date,
            timeslot_id: this.timeslotId,
            duration: value.duration,
            slot_time: date +' '+value.preferredTime
        };
        this.apiService.assignProviderList(data).subscribe((data: any) => {
            // this.assingDocTable = data.data.appointmentEligibleDoctors;
            this.assingDocTable = data.data;
            var status = data.status_code;
        }, error => {
            if (error.status == 0) {
                $('#assignDoctorModal').modal('hide');
                this.logout();
            }
        });
    }

    assignDocSelect(value) {
        this.assignDocSelectedID = value.doctor_id;
        this.allocatedStartTime = this.datePipe.transform(value.slot_time, 'HH:mm');
        this.timeSlotData = value;
    }

    assignProviderSelect(value) {
        if( this.providerType =='1' ){
            this.supportDoctorId = value.user_id;
        }else{
            this.supportDoctorId = value.doctor_id;
        }
        this.allocatedStartTime = this.datePipe.transform(value.slot_time, 'HH:mm');
        this.timeSlotData = value;
    }

    public highlightRow(value) {
        this.selectedTableRow = value;
    }

    forceUpdateTick(newValue) {
        if (newValue[0] == 1) {
            this.forceUpdate = true;
        } else {
            this.forceUpdate = false;
        }
        // var date = "";
        // if (this.availabilityDate!="")
        // date = this.availabilityDate.year + "-" + this.addZ(this.availabilityDate.month)+"-"+this.addZ(this.availabilityDate.day)+" 00:00:00";
        // this.availabilityDate = date;
    }


    /**
     * cancel appoinment
     * @param value
     */
    cancelAppointment(value) {
            Swal.fire({
                text: 'Are you sure you want to cancel the appointment?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.cancelAppointmentAPI(value);
                }
            })
        }

    cancelAppointmentAPI(value) {
        this.apiService.cancleAppoinment(value.reference).subscribe((data: any) => {
            var status = data.status_code;
            if (status == 0) {
                $('#assignDoctorModal').modal('hide');
                this.getAppointments();
                Swal.fire({text: 'Appointment cancellation successful.', icon: 'success'});
            } else if (status == 2) {
                Swal.fire({text: 'The appointment cancellation failed. Appointment in progress', icon: 'error'});
            } else {
                Swal.fire({text: 'The appointment cancellation failed.', icon: 'error'});
            }
        }, error => {
            if (error.status == 0) {
                this.logout();
            }
        });
    }

    getUserId(id: any): void {
        this.timeslotId = id;
    }

    /**
     * update assigned doctor
     * @param value
     * @param time
     * @param onChangeStartTime
     * @param onChangeEndTime
     */
    assignDocSubmit(value, time, onChangeStartTime, onChangeEndTime) {
        if (this.assignDocSelectedID == undefined) {
            this.modifyAvailableDoctorValidation = 1;
            setTimeout(() => {
                this.modifyAvailableDoctorValidation = 0;
            }, 9000);
            return;
        }

        var date = '';
        if (this.availabilityDate.year == undefined) {
            date = value.appointmentDate;
        } else {
            if (this.availabilityDate != '') {
                date = this.availabilityDate.year + '-' + this.addZ(this.availabilityDate.month) + '-' + this.addZ(this.availabilityDate.day) + ' 00:00:00';
            }
        }
        let data = {
            allocatedEnd: onChangeEndTime + ':00',
            allocatedStart: this.allocatedStartTime,
            appointmentDate: date,
            doctorId: this.assignDocSelectedID,
            // duration: value.duration,
            forceUpdate: this.forceUpdate,
            modifiedUserId: localStorage.getItem('userId'),
            timeslotId: this.timeSlotData.id
        };
        this.apiService.assingDocSubmit(value.reference, data).subscribe((data: any) => {
            var status = data;
            if (status.status_code == '0') {
                $('#assignDoctorModal').modal('hide');
                this.getAppointments();
                this.selectedTableRow = null;
                this.forceUpdateShow = 0;
                this.allocatedStartTime = null;
                Swal.fire({text: status.message, icon: 'success'});
            } else {
                Swal.fire({text:status.message, icon: 'error'});
                $('#assignDoctorModal').modal('hide');
                this.getAppointments();
                this.selectedTableRow = null;

            }
        }, error => {
            if (error.status == 0) {
                $('#assignDoctorModal').modal('hide');
                this.logout();
            }
        });

    }


    assignProviderSubmit(value, time, onChangeStartTime, onChangeEndTime) {

        var date = '';
        if (this.availabilityDate.year == undefined) {
            date = value.appointmentDate;
        } else {
            if (this.availabilityDate != '') {
                date = this.availabilityDate.year + '-' + this.addZ(this.availabilityDate.month) + '-' + this.addZ(this.availabilityDate.day) + ' 00:00:00';
            }
        }
        let data = {
            allocatedEnd: onChangeEndTime + ':00',
            allocatedStart: this.allocatedStartTime,
            appointmentDate: date,
            doctorId:  this.supportDoctorId,
            // duration: value.duration,
            forceUpdate: this.forceUpdate,
            modifiedUserId: localStorage.getItem('userId'),
            timeslotId: this.timeSlotData.id,
            slot_time: date

        };
        this.apiService.assignProviderSubmit(value.reference, data).subscribe((data: any) => {
            var status = data;
            if (status.status_code == '0') {
                $('#assignProviderModal').modal('hide');
                this.getAppointments();
                this.selectedTableRow = null;
                this.forceUpdateShow = 0;
                this.allocatedStartTime = null;
                Swal.fire({text: status.message, icon: 'success'});
            } else {
                Swal.fire({text:status.message, icon: 'error'});
                $('#assignProviderModal').modal('hide');
                this.getAppointments();
                this.selectedTableRow = null;

            }
        }, error => {
            if (error.status == 0) {
                $('#assignDoctorModal').modal('hide');
                this.logout();
            }
        });

    }

    public doctorSelectEvent(item) {
        // do something with selected item
        this.docId = item.id;
        this.openAutocomplete();
    }

    public patientSelectEvent(item) {
        // do something with selected item
        this.patientId = item.id;
        this.openAutocomplete();
    }

    onloadAppoinment() {

        let loadAppointment;

        loadAppointment = this.route.snapshot.paramMap.get('id');

        if (loadAppointment != 'all' && loadAppointment > 0) {
            this.selectedAppointmentId = loadAppointment;
        }else {
            this.selectedAppointmentId = -1;
        }

        var data = {
            statusStr: -1,
            lang: this.selectedLanguage,
            appointmentId : this.selectedAppointmentId
        };
        this.apiService.getAppointments(this.jsonToQueryString(data)).subscribe((data: any) => {
            this.appointments = data.data;
            // this.total = data.paginationData.lastPage;
            var status = data.status;
        }, error => {
            if (error.status == 0) {
                this.logout();
            }
        });
    }

    clearAutocomplete() {
        this.doctorAutocomplete.clear();
        this.patientAutocomplete.clear();
    }

    closeAutocomplete() {
        this.doctorAutocomplete.close();
        this.patientAutocomplete.close();
    }

    openAutocomplete() {
        this.doctorAutocomplete.open();
        this.patientAutocomplete.open();
    }

    public clear() {
        this.clearAutocomplete();
        this.closeAutocomplete();
        this.searchReference = '';
        this.page = '';
        this.total = 0;
        this.docId = '';
        this.patientId = '';
        this.statusStr = '';
        this.bookingTypeStr = '';
        this.requestedFrom = '';
        this.requestedTo = '';
        this.specialityID = '';
        this.specialitySelect = ' Select a Specialty';
        this.statusText = 'Select a Status';
        this.bookingTypeText = 'All Appointments';
        this.selectedAppointmentId='all';
        this.onloadAppoinment();
    }

    public selectStatus(id) {
        this.statusStr = `${id}`;
        switch (id) {
            case 0:
                this.statusText = 'Canceled Appointments';
                break;
            case 1:
                this.statusText = 'Doctor on the way';
                break;
            case 2:
                this.statusText = 'Consultation in Progress';
                break;
            case 3:
                this.statusText = 'Consultation Ended';
                break;
            case 4:
                this.statusText = 'Appointment request';
                break;
            case 5:
                this.statusText = 'Missed Appointments';
                break;
        }
    }

    public selectBookingType(id) {
        this.bookingTypeStr = id;
        switch (id) {
            case 1:
                this.bookingTypeText = 'Visit Appointments';
                break;
            case 3:
                this.bookingTypeText = 'Call Appointments';
                break;
            case 5:
                this.bookingTypeText = 'Video Appointments ';
                break;
            case 15:
                this.bookingTypeText = 'All Appointments';
                this.bookingTypeStr = '';
                break;
        }
    }

    public iframeUrl(lat, lng) {
        var url = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyCTmo0PmOHldAm5eqxagk0rleR8IE7HlGw&q=' + lat + ',' + lng;
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    ngAfterViewInit() {
        setTimeout(() => {
        }, 1000);
    }

    public jsonToQueryString(json) {
        return '?' +
            Object.keys(json).map(function (key) {
                if (json[key] != '') {
                    return encodeURIComponent(key) + '=' +
                        encodeURIComponent(json[key]);
                }
            }).join('&');
    }

    addZ(n) {
        return n < 10 ? '0' + n : '' + n;
    }

    /**
     * list all the appointments
     */
    getAppointments() {
        if (this.requestedFrom !== undefined && this.requestedTo !== undefined) {
            var rFrom = '';
            var rTo = '';
            if (this.requestedFrom != '') {
                rFrom = this.requestedFrom.year + '-' + this.addZ(this.requestedFrom.month) + '-' + this.addZ(this.requestedFrom.day) + ' 00:00:00';
            }

            if (this.requestedTo != '') {
                rTo = this.requestedTo.year + '-' + this.addZ(this.requestedTo.month) + '-' + this.addZ(this.requestedTo.day) + ' 23:59:59';
            }

            var dataload = {
                docId: this.docId,
                patientId: this.patientId,
                statusStr: this.statusStr,
                requestedTo: rTo,
                requestedFrom: rFrom,
                page: this.page,
                bookingType: this.bookingTypeStr,
                specialityId: this.specialityID,
                size: this.size,
                lang: this.selectedLanguage,
            };
            this.apiService.getAppointments(this.jsonToQueryString(dataload)).subscribe((data: any) => {
                this.appointments = data.data;
                // this.total = data.paginationData.lastPage;
                var status = data.status_code;
            }, error => {
                if (error.status == 0) {
                    this.logout();
                }
            });
        } else {
            this.appointments = [];
            this.total = 0;
            var rFrom = '';
            var rTo = '';
            var dataload = {
                docId: this.docId,
                patientId: this.patientId,
                statusStr: this.statusStr,
                requestedTo: rTo,
                requestedFrom: rFrom,
                page: this.page,
                bookingType: this.bookingTypeStr,
                specialityId: this.specialityID,
                size: this.size,
                lang: this.selectedLanguage,
            };
            this.apiService.getAppointments(this.jsonToQueryString(dataload)).subscribe((data: any) => {
                this.appointments = data.data;
                // this.total = data.paginationData.lastPage;
                var status = data.status_code;
            }, error => {
                if (error.status == 0) {
                    this.logout();
                }
            });
        }
    }


    public getStatus(data) {
        var response = 'Consultation Cancelled';
        switch (data.status) {
            case 0:
                response = 'Consultation Cancelled';
                break;
            case 1:
                response = 'Doctor on the way';
                break;
            case 2:
                response = 'Consultation Started';
                break;
            case 3:
                response = 'Consultation Ended';
                break;
            case 4:
                response = 'Appointment Request';
                break;
            case 5:
                response = 'Missed Appointments';
                break;
        }

        return response;
    }


    public getStatusClass(data) {
        var response = 'default';
        switch (data.status) {
            case 0:
                response = 'red';
                break;
            case 1:
                response = 'orange';
                break;
            case 2:
                response = 'blue';
                break;
            case 3:
                response = 'green';
                break;
            case 4:
                response = 'purple';
                break;
            case 5:
                response = 'yellow';
                break;
        }

        return response;
    }

    // Appointment time response
    public requestedTime(data) {
        var response = data.preferredTime;
        var responseTime = moment(response).format('hh:mm a');
        return response;

    }

    public appointmentStartTime(data) {
        var response = data.appointmentStartTime;
        var responseTime = moment(response).format('HH:mm a');
        return response;
    }

    public appointmentEndTime(data) {
        var response = data.appointmentEndTime;
        var responseTime = moment(response).format('HH:mm a');
        return response;
    }

    // Appintment time response
    public requestedDate(data) {
        var response = data.requestedDateTime;
        var responseTime = moment(response).format('YYYY-MM-DD HH:mm');
        // var responseTime = moment(response).format('ddd, Do MMM YYYY');
        return responseTime;
    }

}
