import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import { ViewChild } from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import 'datatables.net';
import 'datatables.net-bs4';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';
// import {  FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';


@Component({
  selector: 'app-insurance-users',
  templateUrl: './insurance-users.component.html',
  styleUrls: ['./insurance-users.component.css']
})
export class InsuranceUsersComponent implements OnInit {

  searchForm: FormGroup;
  fileUplaodForm: FormGroup;
  private fileToUpload: File;
  public insuranceUsers: Array<object> = [];
  submitted = false;
  b2bProviderId: any;

  dataTable: any;
  insuranceSearch;

  @ViewChild('userFile') myInputVariable: ElementRef;

  constructor(private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) { }

  ngOnInit() {

    this.b2bProviderId = this.route.snapshot.paramMap.get('id');

    this.getInsuranceUsers();

    this.searchForm = this.formBuilder.group({
      from_date: (''),
      to_date: (''),
      is_verified: -1
    });

    this.fileUplaodForm = this.formBuilder.group({
      file: (''),
    });
  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show');
  }

  expireSessionNow() {
    $('#expireSession').modal('hide');
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
  }

  logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
        .then((res) => {
          this.location.back();
        }, (error) => {
        });
  }

  get f1() { return this.searchForm.controls; }


  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
    });
  }



  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFileToActivity() {
    this.apiService.b2bUsersUpload(this.fileToUpload, this.b2bProviderId).subscribe((data: any) => {
     // this.myInputVariable.nativeElement.value = '';
      const status = data.status_code;
      if (status == 0) {
        this.myInputVariable.nativeElement.value = '';
        this.clearSearch();
        $('#exampleModal').modal('hide');
        Swal.fire({ text: data.message, icon: 'success' });
        location.reload();
      } else {
        this.myInputVariable.nativeElement.value = '';
        Swal.fire({text: data.message, icon: 'error'});
      }
      }, error => {
    });
  }

  public getInsuranceUsers() {

    const data = {
      from_date: '',
      to_date:  '',
      is_verified: '',
      search_flag: 0,
      b2bProviderId : this.b2bProviderId
    };

    this.apiService.getInsuranceUsers(data).subscribe((data: any) => {
      this.insuranceUsers = data.data;
      setTimeout(() => {
        const table: any = $('.bootstrap-3');
        this.dataTable = table.DataTable({
          order: [[ 0, 'desc']],
          processing: true,
          dom: 'lrtipB',
          buttons: [
            {
              extend: 'excelHtml5',
              text: 'Download XLS',
              className: 'buttons-excel',
            },
            {
              extend: 'pdf',
              text: 'Download PDF',
              className: 'buttons-pdf'
            }
          ]
        });
      });  }, error => {});
  }



  searchInsuranceUsers() {

    const data = {
      from_date: this.searchForm.value.from_date,
      to_date: this.searchForm.value.to_date,
      is_verified: this.searchForm.value.is_verified,
      search_flag: 1,
      b2bProviderId : this.b2bProviderId
    };


    this.apiService.getInsuranceUsers(data).subscribe((data: any) => {
      this.insuranceUsers =  [] ;
      this.insuranceUsers = data.data;
    }, error => {});
  }


  clearSearch() {

    this.searchForm.setValue({
      'from_date': '',
      'to_date': '',
      'is_verified': -1,
    });

    const data = {
      from_date: '',
      to_date: '',
      is_verified: '',
      search_flag: 0,
      b2bProviderId : this.b2bProviderId
    };


    this.apiService.getInsuranceUsers(data).subscribe((data: any) => {
      this.insuranceUsers =  [];
      this.insuranceUsers = data.data;
    }, error => {});
  }

   public changeStatus(data) {
    this.apiService.changeStatusB2bUsers(data).subscribe((data: any) => {
      const status = data.status_code;
      if (status == 0) {
        Swal.fire({ text: data.message, icon: 'success' });
        location.reload();
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if (error.status_code == 1) {this.logout(); }});
  }

}
