import {Component, OnInit} from '@angular/core';

import {AuthService} from '../core/auth.service';
import {Router, Params} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {APIService} from '../api.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-delete-request',
    templateUrl: './delete-request.component.html',
    styleUrls: ['./delete-request.component.css']
})
export class DeleteRequestComponent implements OnInit {
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    first_name = '';
    last_name = '';
    mobile_number = '';
    deleteRequestForm: FormGroup;
    errorMessage: string = '';
    submitted = false;


    constructor(private apiService: APIService, public authService: AuthService,
                private router: Router,
                private formBuilder: FormBuilder) {
        this.createForm();
    }


    createForm() {
        this.deleteRequestForm = this.formBuilder.group({
            email: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            mobile_number: ['', Validators.required],
            delete_reason: ['', Validators.required]
        });
    }

    get f2() {
        return this.deleteRequestForm.controls;
    }

    onDelete() {
        this.onDeleteRequest({
            email: this.deleteRequestForm.value.email,
            first_name: this.deleteRequestForm.value.first_name,
            last_name: this.deleteRequestForm.value.last_name,
            mobile_number: this.deleteRequestForm.value.mobile_number,
            delete_reason: this.deleteRequestForm.value.delete_reason,
        });
    }

    onDeleteRequest(value) {
        this.submitted = true;
        if (this.deleteRequestForm.invalid) {
            console.log(this.deleteRequestForm.invalid);
            return;
        } else {

            this.apiService.deleteRequest(value).subscribe((data: any) => {
                var status = data.status_code;
                if (status == 0) {
                    Swal.fire({text: data.message, icon: 'success'});
                } else {
                    Swal.fire({text: data.message, icon: 'error'});
                }
            });
        }

    }

}
