import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import {Routes, RouterModule, Router} from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
    selector: 'app-manage-symptoms',
    templateUrl: './manage-symptoms.component.html',
    styleUrls: ['./manage-symptoms.component.css']
})
export class ManageSymptomsComponent implements OnInit {
    @ViewChild('searchSymptomsAdd') searchSymptomsAdd;
    @ViewChild('searchSymptomsRemove') searchSymptomsRemove;
    private specialitylist: Array<object> = [];
    private specialitySymptomsByIDlist: Array<object> = [];
    private symptomsByIDlist: {};
    private symptomslist: Array<object> = [];
    manageSymptoms = 0;
    symptomsTable = 0;
    public selectedSymptomData: any;
    public selectedSpecialtyData: any;
    specialties = 'name';
    symptoms = 'symptom';
    public symptomsAddDataList: Array<object> = [];
    public symptomsRemoveDataList: Array<object> = [];
    public symptomsValidater: Array<object> = [];
    public symptomsAddDataListIds = [];
    public symptomsRemoveDataListIds = [];
    public onChangeAddDeleteSymptom: any;
    public notFoundAddDelete = 'You can add this symptom ';
    addSymptomsEmpty = 0;
    removeSymptomsEmpty = 0;
    SymptomAlreadySelected = 0;
    SymptomCreateValidator = 0;
    SymptomEditValidator = 0;
    SymptomEditForm = 0;
    selectedLanguage = localStorage.getItem('selectedLanguage');
    createSymptomGreek: any;
    createSymptomEnglish: any;
    editSymptomGreek: any;
    editSymptomEnglish: any;
    addDeleteSymptomsSearch;

    constructor(private apiService: APIService, public data: DataService, public router: Router, private userIdle: UserIdleService, private location: Location, public authService: AuthService) {
    }

    ngOnInit() {
        this.hideAllModals();
        this.loadSpeciality();
        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe((count) => {
            console.log(count), this.expireSession();
        });
        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {
            this.logout(), this.expireSessionNow();
        });
    }

    hideAllModals() {
        $('.modal').modal('hide'); // closes all active pop ups.
        $('.modal-backdrop').remove(); // removes the grey overlay.
    }

    expireSession() {
        $('#expireSession').modal('show');
    }

    expireSessionNow() {
        $('#expireSession').modal('hide');
    }

    Sessionrestart() {
        this.userIdle.resetTimer();
        $('#expireSession').modal('hide');
    }

    logout() {
        this.logoutUser();
        localStorage.clear();
        this.authService.doLogout()
            .then((res) => {
                this.location.back();
            }, (error) => {
                // console.log("Logout error", error);
            });
    }

// API Calls
    logoutUser() {
        let id = localStorage.getItem('userId');
        this.apiService.logoutUser(id).subscribe((data: any) => {
        });
    }

    loadSpeciality() {
        this.apiService.getSpeciality(this.selectedLanguage).subscribe((data: any) => {
            this.specialitylist = data.data;
        }, error => {
            if (error.status == 0) {
                this.hideModals();
                this.logout();
            }
        });
    }

    loadSymptomsList() {
        this.symptomsTable = 1;
        this.apiService.getSymptomsList(this.selectedLanguage).subscribe((data: any) => {
            this.symptomslist = data.data;
        }, error => {
            if (error.status == 0) {
                this.hideModals();
                this.logout();
            }
        });
    }

    loadSpecialitySymptomsByID(id) {
        this.symptomsTable = 1;
        this.apiService.getSpecialitySymptomsByID(id, this.selectedLanguage).subscribe((data: any) => {
            this.specialitySymptomsByIDlist = data.data;
        }, error => {
            if (error.status == 0) {
                this.hideModals();
                this.logout();
            }
        });
    }

    loadSymptomsByID(id) {
        this.apiService.getSymptomsByID(id).subscribe((data: any) => {
            this.symptomsByIDlist = data.data;
            this.editSymptomGreek = this.symptomsByIDlist['symptom'];
            this.editSymptomEnglish = this.symptomsByIDlist['symptomEn'];
        }, error => {
            if (error.status == 0) {
                this.hideModals();
                this.logout();
            }
        });
    }

    removeSymptomsSave() {
        this.getremovesymptomIds();
        if (this.symptomsRemoveDataListIds.length < 1) {
            this.removeSymptomsEmpty = 1;
            setTimeout(() => {
                this.removeSymptomsEmpty = 0;
            }, 9000);
        } else {
            let data = {
                modifiedBy: localStorage.getItem('userId'),
                specialityId: this.selectedSpecialtyData.id,
                symptomIds: this.symptomsRemoveDataListIds
            };
            this.apiService.deleteSpecialitySymptom(data).subscribe((data: any) => {
                this.specialitylist = data.data;
                var status = data.status_code;
                if (status == 0) {
                    this.hideModals();
                    this.closeManageSymptoms();
                    this.selectSpecialityEvent(this.selectedSpecialtyData);
                  Swal.fire({text: 'Successfully removed the symptom', icon: 'success'});
                } else {
                  Swal.fire({text: 'Failed to remove symptom', icon: 'error'});
                }
            }, error => {
                if (error.status == 0) {
                    this.hideModals();
                    this.logout();
                }
            });
        }
    }

    addSymptomsSave() {
        this.getAddsymptomIds();
        if (this.symptomsAddDataListIds.length < 1) {
            this.addSymptomsEmpty = 1;
            setTimeout(() => {
                this.addSymptomsEmpty = 0;
            }, 9000);
        } else {
            let data = {
                modifiedBy: localStorage.getItem('userId'),
                specialityId: this.selectedSpecialtyData.id,
                symptomIds: this.symptomsAddDataListIds
            };
            this.apiService.addSpecialitySymptom(data).subscribe((data: any) => {
                this.specialitylist = data.data;
                var status = data.status_code;
                if (status == 0) {
                    this.hideModals();
                    this.closeManageSymptoms();
                    this.selectSpecialityEvent(this.selectedSpecialtyData);
                  Swal.fire({text: 'Successfully assigned the symptom', icon: 'success'});
                } else {
                  Swal.fire({text: 'Failed to assign symptom', icon: 'error'});
                }
            }, error => {
                if (error.status == 0) {
                    this.hideModals();
                    this.logout();
                }
            });
        }
    }

    getremovesymptomIds() {
        this.symptomsRemoveDataListIds = [];
        this.symptomsRemoveDataList.forEach(element => {
            this.symptomsRemoveDataListIds.push(element['id']);
        });
    }

    getAddsymptomIds() {
        this.symptomsAddDataListIds = [];
        this.symptomsAddDataList.forEach(element => {
            this.symptomsAddDataListIds.push(element['id']);
        });
    }

    closeManageSymptoms() {
        this.removeSymptomsEmpty = 0;
        this.addSymptomsEmpty = 0;
        this.SymptomAlreadySelected = 0;
        this.selectedSymptomData = ' ';
    }

    clearData() {
        this.symptomsAddDataList = [];
        this.symptomsRemoveDataList = [];
        this.SymptomAlreadySelected = 0;
        this.onChangeAddDeleteSymptom = ' ';
        this.createSymptomEnglish = [];
        this.createSymptomGreek = [];
        this.editSymptomGreek = [];
        this.editSymptomEnglish = [];
    }

    selectSpecialityEvent(data) {
        this.selectedSpecialtyData = data;
        this.selectedSymptomData = data;
        this.loadSpecialitySymptomsByID(data.id);
        this.loadSymptomsList();
        this.manageSymptoms = 1;
    }

    selectaddSymptomsEvent(data) {
        const found = this.symptomsAddDataList.find(element => element['id'] == data.id);
        if (found == undefined) {
            this.symptomsAddDataList.push({id: data.id, name: data.symptom});
        } else {
            this.SymptomAlreadySelected = 1;
            setTimeout(() => {
                this.SymptomAlreadySelected = 0;
            }, 9000);
        }
        this.searchSymptomsAdd.clear();
    }

    selectremoveSymptomsEvent(data) {
        const found = this.symptomsRemoveDataList.find(element => element['id'] == data.id);
        if (found == undefined) {
            this.symptomsRemoveDataList.push({id: data.id, name: data.symptom});
        } else {
            this.SymptomAlreadySelected = 1;
            setTimeout(() => {
                this.SymptomAlreadySelected = 0;
            }, 9000);
        }
        this.searchSymptomsRemove.clear();
    }

    addNewDeleteSymptoms() {
        this.clearData();
        this.loadSymptomsList();
        $('#addNewSymptomsModal').modal('show');
    }

  /**
   * create symptoms
   */
  createSymptomsSave() {
        // const found = this.symptomslist.find(element => element['symptom'] == this.onChangeAddDeleteSymptom);
            Swal.fire({
                text: 'Are you sure you want to create Symptom?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {

                if (result.isConfirmed) {


                    if (this.createSymptomEnglish && this.createSymptomGreek) {
                        let data = {
                            is_active: 1,
                            modifiedBy: localStorage.getItem('userId'),
                            score: 0,
                            symptom: this.createSymptomGreek,
                            symptomEn: this.createSymptomEnglish
                        };
                        this.apiService.createSymptoms(data).subscribe((data: any) => {
                            this.specialitylist = data.data;
                            var status = data.status_code;
                            if (status == 0) {
                                Swal.fire({text: 'Successfully created the symptom', icon: 'success'});
                                this.clearData();
                                this.loadSymptomsList();
                            } else {
                                Swal.fire({text: 'Failed to create symptom', icon: 'error'});
                            }
                        }, error => {
                            if (error.status == 0) {
                                this.hideModals();
                                this.logout();
                            }
                        });
                    } else {
                        this.SymptomCreateValidator = 1;
                        setTimeout(() => {
                            this.SymptomCreateValidator = 0;
                        }, 9000);
                    }
                }
            });
    }

    editSymptomCancel() {
        this.SymptomEditForm = 0;
    }

    editSymptom(data) {
        // let language = this.symptomsByIDlist;
        this.SymptomEditForm = 1;
        this.loadSymptomsByID(data.id);
    }

    editSymptomsSave() {

        Swal.fire({
            text: 'Are you sure you want to edit Symptom?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {

            if (result.isConfirmed) {
                let id = this.symptomsByIDlist['id'];
                if (this.editSymptomEnglish && this.editSymptomGreek) {

                    let data = {
                        is_active: 1,
                        modifiedBy: Number(localStorage.getItem('userId')),
                        score: 0,
                        symptom: this.editSymptomGreek,
                        symptomEn: this.editSymptomEnglish
                    };
                    this.apiService.updateSymptoms(id, data).subscribe((data: any) => {
                        var status = data.status_code;
                        if (status == 0) {
                            Swal.fire({text: 'Successfully updated the symptom', icon: 'success'});
                            this.clearData();
                            this.loadSymptomsList();
                        } else {
                            Swal.fire({text: 'Failed to edit symptom', icon: 'error'});
                        }
                    }, error => {
                        if (error.status == 0) {
                            // this.hideModals(); this.logout()
                        }
                    });

                } else {
                    this.SymptomEditValidator = 1;
                    setTimeout(() => {
                        this.SymptomEditValidator = 0;
                    }, 9000);
                }
            }
        });
    }

  /**
   * delete symptom
   * @param data
   */
  deletCereateSymptomSave(data) {


        Swal.fire({
            text: 'Are you sure to delete Symptom?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {

            if (result.isConfirmed) {
                let userID = Number(localStorage.getItem('userId'));
                this.apiService.deleteSymptoms(data.id, userID).subscribe((data: any) => {
                    this.specialitylist = data.data;
                    var status = data.status_code;
                    if (status == 0) {
                        Swal.fire({text: 'Successfully deleted the symptom', icon: 'success'});
                        this.clearData();
                        this.loadSymptomsList();
                    } else {
                        Swal.fire({text: 'Failed to create symptom', icon: 'error'});
                    }
                }, error => {
                    if (error.status == 0) {
                        this.hideModals();
                        this.logout();
                    }
                });
            }
        });
    }


    addSymptoms(value) {
        this.clearData();
        if (value == 1) {
            this.hideModals();
        } else {
            $('#addSymptomsModal').modal('show');
        }
    }

    removeSymptoms(value) {
        this.clearData();
        if (value == 1) {
            this.hideModals();
        } else {
            this.symptomsRemoveDataList;
            $('#removeSymptomsModal').modal('show');
        }
    }


    removeSymptemsArray(data) {
        this.symptomsRemoveDataList = this.symptomsRemoveDataList.filter(item => ![data].includes(item));
    }

    AddSymptemsArray(data) {
        this.symptomsAddDataList = this.symptomsAddDataList.filter(item => ![data].includes(item));
    }

    hideModals() {
        this.symptomsAddDataList = [];
        this.symptomsRemoveDataList = [];
        $('#addSymptomsModal').modal('hide');
        $('#removeSymptomsModal').modal('hide');
        $('#addNewSymptomsModal').modal('hide');
    }

}
