import {Component, OnInit } from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Key } from 'selenium-webdriver';
import {ValidationService} from '../validation.service';

@Component({
  selector: 'app-insurance-provider',
  templateUrl: './insurance-provider.component.html',
  styleUrls: ['./insurance-provider.component.css']
})

export class InsuranceProviderComponent implements OnInit {

  newInsuranceProviderForm: FormGroup;
  editInsuranceProviderForm: FormGroup;

  submitted = false;

  public insuranceProviders: Array<object> = [];
  private newInsuranceProvider = {};
  private editInsuranceProvider = {};

  insuranceSearch;


  constructor(private validationService : ValidationService, private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) { }

  ngOnInit() {
    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      console.log(count), this.expireSession();
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      this.logout(), this.expireSessionNow();
    });

    this.getInsuranceProviders();

    this.newInsuranceProviderForm = this.formBuilder.group({
      name: ['', [Validators.required, this.validationService.nameValidator]],
      reference_number: ['', Validators.required],
      telephone: ['', [Validators.required, this.validationService.numberValidator]],
      contact_person: ['', [Validators.required, this.validationService.nameValidator]],
      contact_person_telephone: ['', [Validators.required, this.validationService.numberValidator]],
      address: ['', [Validators.required]],
      is_active: ''
    });

    this.editInsuranceProviderForm = this.formBuilder.group({
      name: ['', [Validators.required, this.validationService.nameValidator]],
      reference_number: ['', Validators.required],
      telephone: ['', [Validators.required, this.validationService.numberValidator]],
      contact_person: ['', [Validators.required, this.validationService.nameValidator]],
      contact_person_telephone: ['', [Validators.required, this.validationService.numberValidator]],
      address: ['', [Validators.required]],
      is_active: '',
      b2b_provider_id: ['', [Validators.required]],
    });
  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show');
  }

  expireSessionNow() {
    $('#expireSession').modal('hide');
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
  }

  logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
        .then((res) => {
          this.location.back();
        }, (error) => {
        });
  }


  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
    });
  }

  get f1() { return this.newInsuranceProviderForm.controls; }
  get f2() { return this.editInsuranceProviderForm.controls; }



  public getInsuranceProviders() {
    this.apiService.getInsuranceProviders().subscribe((data: any) => {
      this.insuranceProviders = data.data;
    }, error => {});
  }


  onSubmit() {

    this.submitted = true;

    if (this.newInsuranceProviderForm.invalid) {
      return;
    }
    
    const data = {
      name: this.newInsuranceProviderForm.value.name,
      reference_number: this.newInsuranceProviderForm.value.reference_number,
      telephone: this.newInsuranceProviderForm.value.telephone,
      contact_person: this.newInsuranceProviderForm.value.contact_person,
      contact_person_telephone: this.newInsuranceProviderForm.value.contact_person_telephone,
      address: this.newInsuranceProviderForm.value.address,
      is_active: this.newInsuranceProviderForm.value.is_active === true ? 1 : 0
    };


    this.apiService.addInsuranceProvider(data).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.getInsuranceProviders();
        this.newInsuranceProviderForm.reset();
        Swal.fire({ text: data.message, icon: 'success' });
        $('#newB2bProvider').modal('hide');

      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if (error.status == 0) {$('#newInsuranceProvider').modal('hide'); this.logout(); }});

  }

  public editInsuranceProviders(editInsuranceProvider) {

    this.submitted = true;

    if (this.editInsuranceProviderForm.invalid) {
      return;
    }

    let data = {
      name: this.editInsuranceProviderForm.value.name,
      reference_number: this.editInsuranceProviderForm.value.reference_number,
      telephone: this.editInsuranceProviderForm.value.telephone,
      contact_person: this.editInsuranceProviderForm.value.contact_person,
      contact_person_telephone: this.editInsuranceProviderForm.value.contact_person_telephone,
      address: this.editInsuranceProviderForm.value.address,
      is_active: this.editInsuranceProviderForm.value.is_active === true ? 1 : 0,
      b2b_provider_id: this.editInsuranceProviderForm.value.b2b_provider_id,
    };

    this.apiService.editInsuranceProviders(data).subscribe((data: any) => {
      this.submitted = false;
      const status = data.status_code;
      if (status == 0) {
        this.getInsuranceProviders();
        this.editInsuranceProviderForm.reset();
        Swal.fire({ text: data.message, icon: 'success' });
        $('#editInsuranceProvider').modal('hide');
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, );
  }


  public showInsuranceProvider(id) {
    this.apiService.getB2bProviderById(id).subscribe((data: any) => {
      const status = data.status_code;
      this.editInsuranceProvider = data.data;
      this.editInsuranceProviderForm.setValue({
        'name':  this.editInsuranceProvider['name'],
        'reference_number': this.editInsuranceProvider['reference_number'] ,
        'telephone': this.editInsuranceProvider['telephone'],
        'contact_person' : this.editInsuranceProvider['contact_person'],
        'contact_person_telephone' : this.editInsuranceProvider['contact_person_telephone'],
        'address' : this.editInsuranceProvider['address'],
        'is_active' : this.editInsuranceProvider['is_active'] === 1 ? true : false,
        'b2b_provider_id' : this.editInsuranceProvider['id'],
      });
    }, error => { if (error.status == 0) {$('#editInsuranceProvider').modal('hide'); this.logout(); }} );
  }

  public changeStatus(data) {
    this.apiService.changeStatusB2bProviders(data).subscribe((data: any) => {
      const status = data.status_code;
      if (status == 0) {
        this.getInsuranceProviders();
        Swal.fire({ text: data.message, icon: 'success' });
      } else {
        Swal.fire({text: data.message, icon: 'error'});
      }
    }, error => { if(error.status_code == 1){this.logout()}});
  }


}
