import {AfterViewInit, Component, OnInit} from '@angular/core';
import {APIService} from '../api.service';
import {DataService} from '../data.service';
import 'datatables.net';
import 'datatables.net-bs4';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderPipe} from 'ngx-order-pipe';
import {UserIdleService} from 'angular-user-idle';
import {Location} from '@angular/common';
import {AuthService} from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'provider-package-users',
  templateUrl: './provider-package-users.component.html',
  styleUrls: ['./provider-package-users.component.css']
})
export class ProviderPackageUsersComponent implements OnInit {

  public userPackagesUsers: Array<object> = [];
  dataTable: any;
  userPackageId: any;

  packageUserSearch;

  constructor(private userIdle: UserIdleService, public authService: AuthService, private route: ActivatedRoute, private location: Location, private apiService: APIService, public data: DataService, private router: Router, private formBuilder: FormBuilder, private orderPipe: OrderPipe) { }

  ngOnInit() {
    this.userPackageId = this.route.snapshot.paramMap.get('id');
    this.getUserPackageUsers();
  }

  hideAllModals() {
    $('.modal').modal('hide'); // closes all active pop ups.
    $('.modal-backdrop').remove(); // removes the grey overlay.
  }

  expireSession() {
    $('#expireSession').modal('show');
  }

  expireSessionNow() {
    $('#expireSession').modal('hide');
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide');
  }

  logout() {
    this.logoutUser();
    localStorage.clear();
    this.authService.doLogout()
        .then((res) => {
          this.location.back();
        }, (error) => {
        });
  }

  logoutUser() {
    const id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
    });
  }

  public getUserPackageUsers() {

    const data = {
      user_package_id:  this.userPackageId
    };

    console.log(data);

    this.apiService.getUserPackageUsers(data).subscribe((data: any) => {
      this.userPackagesUsers = data.data;
      setTimeout(() => {
        const table: any = $('.bootstrap-3');
        this.dataTable = table.DataTable({
          processing: true,
          dom: 'lrtipB',
          buttons: [
            {
              extend: 'excelHtml5',
              text: 'Download XLS',
              className: 'buttons-excel',
            },
            {
              extend: 'pdf',
              text: 'Download PDF',
              className: 'buttons-pdf'
            }
          ]
        });
      });  }, error => {});
  }

}
