import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FileSelectDirective } from 'ng2-file-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http'; 
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { PatientListComponent } from './patient-list/patient-list.component';
import { FareListComponent } from './fare-list/fare-list.component';
import { LoginControlComponent } from './login-control/login-control.component';
import { AppRoutingModule } from './app-routing.module';
import * as $ from 'jquery';
import * as bootstrap from "bootstrap";
import 'datatables.net';
import 'datatables.net-bs4';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { AgmCoreModule } from '@agm/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DoctestComponent } from './doctest/doctest.component';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { MissedAppointmentListComponent } from './missed-appointment-list/missed-appointment-list.component';
import { StatsComponent } from './stats/stats.component';
import { PromosComponent } from './promos/promos.component';

import { UserResolver } from './stats/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';

import {AutocompleteLibModule} from 'angular-ng-autocomplete';

import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ChecklistModule } from 'angular-checklist';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';
import { CKEditorModule } from 'ckeditor4-angular';
import { UserIdleModule } from 'angular-user-idle';
import { DatePipe } from '@angular/common';
import { FirestoreSettingsToken} from '@angular/fire/firestore';
import { ChartsModule } from 'ng2-charts';
import { ManageSymptomsComponent } from './manage-symptoms/manage-symptoms.component';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { firebaseConfig } from '../app/firabase';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppointmentSettingsComponent } from './appointment-settings/appointment-settings.component';
import { SearchDoctorTimeSessionComponent } from './search-doctor-time-session/search-doctor-time-session.component';
import { ServicesComponent } from './services/services.component';
import { InsuranceProviderComponent } from './insurance-provider/insurance-provider.component';
import { InsuranceUsersComponent } from './insurance-users/insurance-users.component';
import { PackageListingComponent } from './package-listing/package-listing.component';
import { PackageReportComponent } from './package-report/package-report.component';
import { UserPackageComponent } from './user-package/user-package.component';
import { UserPackageUsersComponent } from './user-package-users/user-package-users.component';
import { ProviderPackageComponent } from './provider-package/provider-package.component';
import { ProviderPackageUsersComponent } from './provider-package-users/provider-package-users.component';
import {ProvidersComponent} from './providers/providers.component';
import { ProviderSettingsComponent} from './provider-settings/provider-settings.component';
import { SafePipe } from './safe.pipe';
import { VideoRecordsComponent } from './video-records/video-records.component';
import { DeleteRequestComponent } from './delete-request/delete-request.component';

@NgModule({
  declarations: [
    DashboardListComponent,
    AppComponent,
    PatientListComponent,
    FareListComponent,
    LoginControlComponent,
    HomeLayoutComponent,
    DoctestComponent,
    AppointmentListComponent,
    MissedAppointmentListComponent,
    StatsComponent,
    PromosComponent,
    ManageSymptomsComponent,
    AppointmentSettingsComponent,
    ProviderSettingsComponent,
    SearchDoctorTimeSessionComponent,
    ServicesComponent,
    InsuranceProviderComponent,
    InsuranceUsersComponent,
    PackageListingComponent,
    PackageReportComponent,
    UserPackageComponent,
    UserPackageUsersComponent,
    ProviderPackageComponent,
    ProviderPackageUsersComponent,
      ProvidersComponent,
      SafePipe,
      VideoRecordsComponent,
      DeleteRequestComponent
    //FileSelectDirective

  ],
  imports: [ 
    ChartsModule, CKEditorModule, OrderModule, NgxPaginationModule, Ng2SearchPipeModule, ChecklistModule,AutocompleteLibModule,BrowserModule,FormsModule , ReactiveFormsModule,
    HttpClientModule,
      AppRoutingModule, AgmCoreModule.forRoot({
        apiKey:'AIzaSyCTmo0PmOHldAm5eqxagk0rleR8IE7HlGw',
        libraries: ["places"]
      }),
    NgbModule, AngularFireModule.initializeApp(environment.firebase),
   AngularFireDatabaseModule,
   AngularFireAuthModule,
   AngularFirestoreModule,
   UserIdleModule.forRoot({idle: 1800, timeout: 300, ping: 120}), //session timeout  time
   NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    { provide: FirestoreSettingsToken, useValue: {} },
    AuthGuard,
    AuthService,
    UserResolver,
    UserService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
