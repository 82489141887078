import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../core/auth.service';
import { Router } from '@angular/router';
import { APIService } from '../api.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {


  public docUserRole = " ";

  public show:boolean = false;
  public Hide:boolean = true;
  public PracticeManagerButton:any = 'Show';
  public UID = " ";
  private flag: boolean;
  selectedLanguage = 'en';

  constructor(private apiService: APIService, private location: Location, public authService: AuthService, private router: Router) {

  }

  languageChange(value){
    if(value == 1){
      this.selectedLanguage = 'el';
      localStorage.setItem('selectedLanguage', 'el');
      window.location.reload();
    } else if (value == 2)  {
      this.selectedLanguage = 'en';
      localStorage.setItem('selectedLanguage', 'en');
      window.location.reload();
    }
  }

  ngOnInit() {
    this.selectedLanguage = localStorage.getItem('selectedLanguage');
    this.hideAllModals()
    if ((Notification as any).permission != "granted") {
      Notification.requestPermission((perm) => {

      })
    }

    this.docUserRole = localStorage.getItem("user_role");

  }
  hideAllModals(){
    $('.modal').modal('hide') // closes all active pop ups.
    $('.modal-backdrop').remove() // removes the grey overlay.
  }

  logout() {
    this.logoutUser()
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        // console.log("Logout error", error);
      });
  }

  logoutUser(){
    let id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }

  PracticeManagerToggle() {
    this.show = !this.show;
    this.Hide = !this.Hide;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.Hide )  
      this.PracticeManagerButton = "Hide";
    else
      this.PracticeManagerButton = "Show";
  }
  





}
