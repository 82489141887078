import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APIService } from '../api.service';
import 'datatables.net';
import 'datatables.net-bs4';
import * as moment from 'moment';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgbDatepicker, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-missed-appointment-list',
    templateUrl: './missed-appointment-list.component.html',
    styleUrls: ['./missed-appointment-list.component.css']
})
export class MissedAppointmentListComponent implements OnInit, OnChanges {

    private appointments: Array<object> = [];
    public fromDate: { year: number; month: number; day: number; };
    public toDate: { year: number; month: number; day: number; };
    public status: any;
    dataTable: any;
    public page = "";
    public total = "";
    public bookingTypeStr = "";
    public bookingTypeText = "All Appointments";
    public user_role: boolean;


    // @ViewChild("d", { read: NgbInputDatepicker })
    // public datePicker1: NgbInputDatepicker;

    constructor(private apiService: APIService, protected sanitizer: DomSanitizer, private router: Router, private activatedRoute: ActivatedRoute) {
        
        router.events.subscribe((e) => {

            if (e instanceof NavigationEnd) {
                // const navEvent = e as NavigationEnd;

                activatedRoute.queryParams.subscribe((params) => {
                    if (params.requestedFrom) {
                        const dateFrom: string = params.requestedFrom;
                        const dateTo: string = params.requestedTo;

                        const mFrom = moment(dateFrom, "YYYY-MM-DD");
                        const mTo = moment(dateTo, "YYYY-MM-DD");

                        this.fromDate = {
                            day: mFrom.get("date"),
                            month: mFrom.get("month"),
                            year: mFrom.get("year")
                        };
                        this.toDate = {
                            day: mTo.get("date"),
                            month: mTo.get("month"),
                            year: mTo.get("year")
                        };
                        console.log(this.fromDate, this.toDate)

                        this.getMissedAppointments();

                        // service call
                        this.apiService.getAlerts().subscribe((data: any) => {
                        });


                    }
                });
            }

        })

        
    }

    ngOnInit() {
        //this.getMissedAppointments();
        this.hideAllModals();
    }

    hideAllModals(){
        $('.modal').modal('hide') // closes all active pop ups.
        $('.modal-backdrop').remove() // removes the grey overlay.
      }

    ngOnChanges(changes: SimpleChanges): void {

        alert("change");
    }

    public jsonToQueryString(json) {
        return '?' +
            Object.keys(json).map(function (key) {
                if (json[key] != "") {
                    return encodeURIComponent(key) + '=' +
                        encodeURIComponent(json[key]);
                }
            }).join('&');
    }
    public selectStatus(id) {
        this.status = id;
        switch (id) {
            case 0: this.status = 'Doctor Canceled'; break;
            case 1: this.status = 'Patient Cancelled'; break;
            case 2: this.status = 'Got delayed'; break;
            case 3: this.status = 'Payment fault'; break;
        }
    }
    public selectBookingType(id) {
        this.bookingTypeStr = id;
        switch (id) {
            case 1: this.bookingTypeText = 'Visit Appointments'; break;
            case 3: this.bookingTypeText = 'Call Appointments'; break;
            case 15: this.bookingTypeText = 'All Appointments';
                this.bookingTypeStr = "";
                break;
        }
    }
    public onDateSelect($event) {
        // console.log($event);
    }

    public iframeUrl(lat, lng) {
        var url = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCvSbthci6k33TPo6Z4TU2XbJT42lddSUQ&q=" + lat + "," + lng;
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public clear() {

        this.fromDate = null;
        this.toDate = null;
        this.appointments = [];
        this.bookingTypeText = "";
        this.page = "";
        this.total = "";

    }

    addZ(n) { return n < 10 ? '0' + n : '' + n; }

    public getMissedAppointments() {

        var dataload = {
            requestedTo: this.toDate.year + "-" + this.addZ(this.toDate.month) + "-" + this.addZ(this.toDate.day) + " 23:59:00",
            requestedFrom: this.fromDate.year + "-" + this.addZ(this.fromDate.month) + "-" + this.addZ(this.fromDate.day) + " 00:00:00",
            bookingType: this.bookingTypeStr,
            page: this.page,
            size: ""

        }
        this.apiService.getAppointmentsTest(this.jsonToQueryString(dataload)).subscribe((data: any) => {
            this.appointments = data.data;
            this.total = data.paginationData.lastPage;
        });

        // this.apiService.getMissedAppointments(this.fromDate.year + "-" + this.addZ(this.fromDate.month)+"-"+this.addZ(this.fromDate.day)+" 00:00:00",this.toDate.year + "-" + this.addZ(this.toDate.month)+"-"+this.addZ(this.toDate.day)+" 23:59:00",this.bookingTypeStr).subscribe((data:  any) => {
        //     this.appointments  =  data.data;          
        //     console.log(data);
        // });


    }


    public requestedDate(data) {
        var response = data.requestedDate;
        var responseTime = moment(response).format('ddd, Do MMM YYYY');
        return responseTime;
    }

    public requestedTime(data) {
        var response = data.requestedDate;
        var responseTime = moment(response).format('HH:mm a');
        return responseTime;
    }
}
