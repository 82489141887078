import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from "../data.service";
import { APIService } from '../api.service';
import * as moment from 'moment';
import { Router } from "@angular/router";
import { UserIdleService } from 'angular-user-idle';
import { Location } from '@angular/common';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-search-doctor-time-session',
  templateUrl: './search-doctor-time-session.component.html',
  styleUrls: ['./search-doctor-time-session.component.css']
})
export class SearchDoctorTimeSessionComponent implements OnInit {
  @ViewChild('doctorAutocomplete' ) doctorAutocomplete;
  @ViewChild('specialityAutocomplete' ) specialityAutocomplete;
  private activeDoctorlist: Array<object> = [];
  activeDocters = 'name';
  speciality = 'name';
  selectedLanguage = localStorage.getItem('selectedLanguage');
  private specialityList: Array<object> = [];
  private timeSlots: Array<object> = [];

  constructor(public data: DataService, private apiService: APIService, protected sanitizer: DomSanitizer, private router: Router, private userIdle: UserIdleService, private location: Location, public authService: AuthService) { }

  ngOnInit() {
    this.getDoctors()
    this.hideAllModals();
    this.Speciality();
            // Start watching when user idle is starting.
            this.userIdle.onTimerStart().subscribe((count) => { console.log(count), this.expireSession() });
            // Start watch when time is up.
            this.userIdle.onTimeout().subscribe(() => { this.logout(), this.expireSessionNow() });
            this.timeList()
  }

  hideAllModals(){
    $('.modal').modal('hide') // closes all active pop ups.
    $('.modal-backdrop').remove() // removes the grey overlay.
  }
  

  expireSession() {
    $('#expireSession').modal('show')
}
expireSessionNow() {
    $('#expireSession').modal('hide')
}
Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide')
}
logout() {
    this.logoutUser()
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        // console.log("Logout error", error);
      });
  }

  logoutUser(){
    let id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }

  public getDoctors() {
    this.apiService.getDoctors(this.selectedLanguage).subscribe((data: any) => {
      this.activeDoctorlist = data.data;
    }, error => { if(error.status == 0){this.logout()}});
  }

  public Speciality(){
    this.apiService.getSpeciality(this.selectedLanguage).subscribe((data: any) => {
      this.specialityList = data.data;
  });
  }

  AppointmentEdit(){
    $('#exampleModal').modal('show')
  }


  public timeList(){
    this.timeSlots = [
      {  time: "00:00" },
      {  time: "00:15" },
      {  time: "00:30" },
      {  time: "00:45" },
      {  time: "01:00" },
      {  time: "01:15" },
      {  time: "01:30" },
      {  time: "01:45" },
      {  time: "02:00" },
      {  time: "02:15" },
      {  time: "02:30" },
      {  time: "02:45" },
      {  time: "03:00" },
      {  time: "03:15" },
      {  time: "03:30" },
      {  time: "03:45" },
      {  time: "04:00" },
      {  time: "04:15" },
      {  time: "04:30" },
      {  time: "04:45" },
      {  time: "05:00" },
      {  time: "05:15" },
      {  time: "05:30" },
      {  time: "05:45" },
      {  time: "06:00" },
      {  time: "06:15" },
      {  time: "06:30" },
      {  time: "06:45" },
      {  time: "07:00" },
      {  time: "07:15" },
      {  time: "07:30" },
      {  time: "07:45" },
      {  time: "08:00" },
      {  time: "08:15" },
      {  time: "08:30" },
      {  time: "08:45" },
      {  time: "09:00" },
      {  time: "09:15" },
      {  time: "09:30" },
      {  time: "09:45" },
      {  time: "10:00" },
      {  time: "10:15" },
      {  time: "10:30" },
      {  time: "10:45" },
      {  time: "11:00" },
      {  time: "11:15" },
      {  time: "11:30" },
      {  time: "11:45" },
      {  time: "12:00" },
      {  time: "12:15" },
      {  time: "12:30" },
      {  time: "12:45" },
      {  time: "13:00" },
      {  time: "13:15" },
      {  time: "13:30" },
      {  time: "13:45" },
      {  time: "14:00" },
      {  time: "14:15" },
      {  time: "14:30" },
      {  time: "14:45" },
      {  time: "15:00" },
      {  time: "15:15" },
      {  time: "15:30" },
      {  time: "15:45" },
      {  time: "16:00" },
      {  time: "16:15" },
      {  time: "16:30" },
      {  time: "16:45" },
      {  time: "17:00" },
      {  time: "17:15" },
      {  time: "17:30" },
      {  time: "17:45" },
      {  time: "18:00" },
      {  time: "18:15" },
      {  time: "18:30" },
      {  time: "18:45" },
      {  time: "19:00" },
      {  time: "19:15" },
      {  time: "19:30" },
      {  time: "19:45" },
      {  time: "20:00" },
      {  time: "20:15" },
      {  time: "20:30" },
      {  time: "20:45" },
      {  time: "21:00" },
      {  time: "21:15" },
      {  time: "21:30" },
      {  time: "21:45" },
      {  time: "22:00" },
      {  time: "22:15" },
      {  time: "22:30" },
      {  time: "22:45" },
      {  time: "23:00" },
      {  time: "23:15" },
      {  time: "23:30" },
      {  time: "23:45" },
      {  time: "24:00" },
      {  time: "24:15" },
      {  time: "24:30" },
      {  time: "24:45" },
      
    ];
  }

}
