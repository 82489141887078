import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { AngularFireDatabase } from "angularfire2/database";
import { Appearance} from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import {MapsAPILoader, MouseEvent } from '@agm/core';
import { Observable } from 'rxjs';
import { Chart } from 'chart.js';
import {APIService} from '../api.service';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from '../core/auth.service';
import { Location } from '@angular/common';

import {Router} from "@angular/router";
// import { google } from '@agm/core/services/google-maps-types';


@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.css']
})
export class DashboardListComponent implements OnInit {

AutoCompletekeyword = 'docId' && 'docName';
public doctors: Observable<any[]>;
private docList: Array<object> = [];
private doctorLocationList : Array<object> = [];
public user_role: boolean;
public latitude: any = '';
public longitude: any = '';
zoom: number = 7;
geocode: string;
private geoCoder;
locationAutocompleate : any;

@ViewChild('doctorSearch') doctorSearch;
@ViewChild('search') 
public searchElementRef: ElementRef;

  constructor(private userIdle: UserIdleService, private location: Location, public authService: AuthService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private db: AngularFireDatabase,private apiService: APIService,  private router: Router) { }
  
  addZ(n){return n<10? '0'+n:''+n;}
  ngOnInit() {
    this.defaultLocation()
    this.doctorLocations()
    this.hideAllModals();
      this.doctors = this.db.list('/doctors').valueChanges();
     this.apiService.getDoctorDetail().subscribe((data: any) => {
      this.docList = data.data;
    });
         //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ["geocode"]  // 'establishment' / 'address' / 'geocode'
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 13;
        });
      });
    });

    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession()} );
    // Start watch when time is up.
    this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow()} ); 
  }

  defaultLocation(){
    this.latitude= 37.9838;
    this.longitude= 23.7275;
    this.zoom = 10;
  }

  locationReset(){
    this.defaultLocation()
    this.doctorSearch.clear();
    this.doctorSearch.close();
    this.locationAutocompleate = []
  }

  doctorLocations (){
    this.apiService.doctorLocations().subscribe((data: any) => {
      this.doctorLocationList = data.data.doctorLocations;
  }, error => { if (error.status == 0) {  this.logout() } });
  }

  hideAllModals(){
    $('.modal').modal('hide') // closes all active pop ups.
    $('.modal-backdrop').remove() // removes the grey overlay.
  }

  expireSession(){
    $('#expireSession').modal('show')
  }

  expireSessionNow(){
    $('#expireSession').modal('hide')
  }

  Sessionrestart() {
    this.userIdle.resetTimer();
    $('#expireSession').modal('hide')
  }


  logout() {
    this.logoutUser()
    localStorage.clear();
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        // console.log("Logout error", error);
      });
  }

  logoutUser(){
    let id = localStorage.getItem('userId');
    this.apiService.logoutUser(id).subscribe((data: any) => {
  });
  }

    // Get Current Location Coordinates
    private setCurrentLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 8;
          this.getAddress(this.latitude, this.longitude);
        });
      }
    }

    getAddress(latitude, longitude) {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 12;
            this.geocode = results[0].formatted_geocode;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
   
      });
    }

    // public  onLocationSelected(location: Location) {
    //   console.log('onLocationSelected: ', location);
    //   this.latitude = location.latitude;
    //   this.longitude = location.longitude;
    // }


    public AutoCompleatselectDoctor(item) {
      this.latitude = parseFloat (item.latitude);
      this.longitude = parseFloat (item.longitude);
      this.zoom = 16;
    }

    parseNum(val){
        return parseFloat(val);
    }

}
