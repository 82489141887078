import { Injectable } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  nameValidator(control: FormControl): { [key: string]: any } {
    const nameRegexp = /^[a-zA-Z\s]*$/;
    if (control.value && !nameRegexp.test(control.value)) {
      return { invalidName: true };
    }
  }

  numberValidator(control: FormControl): { [key: string]: any } {
    const numericRegexp = /^\d*$/;
    if (control.value && !numericRegexp.test(control.value)) {
      return { invalidNumber: true };
    }
  }

  numericValidator(control: FormControl): { [key: string]: any } {
    const numericRegexp = /^[0-9]*$/;
    if (control.value && !numericRegexp.test(control.value)) {
      return { invalidNumber: true };
    }
  }
}
