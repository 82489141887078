import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators'; 
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

@Injectable()
export class UserService {

  constructor(
   public db: AngularFirestore,
   public afAuth: AngularFireAuth
 ){
 }


  getCurrentUser(){
    return new Promise<any>((resolve, reject) => {
      if (localStorage.getItem('userId') !== null){
        resolve(localStorage.getItem('userId'));
      }else {
        reject('No user logged in');
      }
      // var user = firebase.auth().onAuthStateChanged(function(user){
      //   if (user) {
      //     resolve(user);
      //   } else {
      //     reject('No user logged in');
      //   }
      // })
    })
  }

}
