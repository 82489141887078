import { Component, OnInit, AfterViewInit } from '@angular/core';
import { APIService } from '../api.service';
import { DataService } from "../data.service";
import 'datatables.net';
import 'datatables.net-bs4';
import { Router } from "@angular/router";
import * as moment from 'moment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Snapshot } from 'elasticsearch';
import { OrderPipe } from 'ngx-order-pipe';
import { UserIdleService } from 'angular-user-idle';
import { Location } from '@angular/common';
import { AuthService } from '../core/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
    selector: 'app-patient-list',
    templateUrl: './patient-list.component.html',
    styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit, AfterViewInit {

    private patients: Array<object> = [];
    private selected = {};
    dataTable: any;
    public viewPatient = true;
    public deleteBox = false;
    public deleteConfirm = false;
    public reason: any;
    public new_edit_picture:any;
    public fileToUpload2:any;
    public files:any;
    public user_role: boolean;
    order: string = ' ';
    reverse: boolean = false;
    PatientSearch;
    constructor(private apiService: APIService, public data: DataService,private router: Router, private orderPipe: OrderPipe, private userIdle: UserIdleService, private location: Location, public authService: AuthService) {}

    ngOnInit() {
        // this.user_role = localStorage.getItem("user_role")=="1"?true:false;
        this.getPatients();
        this.hideAllModals();
        this.userIdle.startWatching();
    
        // Start watching when user idle is starting.
        this.userIdle.onTimerStart( ).subscribe((count) =>  { console.log(count), this.expireSession()} );
        
        // Start watch when time is up.
        this.userIdle.onTimeout( ).subscribe(( ) => {this.logout(), this.expireSessionNow()} );
    }

    hideAllModals(){
        $('.modal').modal('hide') // closes all active pop ups.
        $('.modal-backdrop').remove() // removes the grey overlay.
      }
    expireSession(){

        $('#expireSession').modal('show')
    
      }
    
      expireSessionNow(){
    
        $('#expireSession').modal('hide')
    
      }
    
      Sessionrestart() {
        this.userIdle.resetTimer();
        $('#expireSession').modal('hide')
      }
    
    
      logout() {
        this.logoutUser()
        localStorage.clear();
        this.authService.doLogout()
          .then((res) => {
            this.location.back();
          }, (error) => {
            // console.log("Logout error", error);
          });
      }
    
      logoutUser(){
        let id = localStorage.getItem('userId');
        this.apiService.logoutUser(id).subscribe((data: any) => {
      });
      }

    ngAfterViewInit() {
        setTimeout(() => {
            const table: any = $('.bootstrap-3')
            this.dataTable = table.DataTable(
                {
                    dom: 'Bfrtip',
                    buttons: [
                        'excel', 'pdf'
                    ]
                }
            );
        }, 1000);

    }

    public getPatients() {
        this.apiService.getPatients().subscribe((data: any) => {
            this.patients = data.data;
            var status = data.status;
        }, error => { if(error.status == 0){this.logout()}});
    }

    public editFile(event: any) {
        
    if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            // console.log(event.target);
            reader.onload = (event: any) => {
                
                this.new_edit_picture = event.target.result;
            }
            reader.readAsDataURL(event.target.files[0]);
            this.fileToUpload2 = event.target.files[0];
        }
  }

    public editPatient(editPatient){
        if (this.fileToUpload2!=undefined&&this.fileToUpload2.name !=""){
            let formData = new FormData(); 
            formData.append("file", this.fileToUpload2, this.fileToUpload2.name); 
            this.apiService.addImage(formData).subscribe((val:any) => {
                 if(val.status.status == "SUCCESS")
                {
                    var data = {
                        firstName:editPatient.firstName,
                        lastName:editPatient.lastName,
                        email:editPatient.email,
                        address:editPatient.address,
                        mobileNumber:editPatient.mobileNumber,
                        gardianName:editPatient.gardianName,
                        profilePicture: val.data.url,
                        gender:editPatient.genderV,
                        gardianNumber:editPatient.gardianNumber,
                        nhsnumber:editPatient.nhsnumber
                    }
                    this.apiService.editPatient(editPatient.id,data).subscribe((data: any) => {
                        var status = data.status;
                        // console.log(status);
                          if(status.status == "SUCCESS"){
                            alert("Patient updated successfully");
                            $("#editdoctor").modal('hide');
                            window.location.reload();
                        }
                        else{
                            alert(status.reason);
                        }
                    }, error => { if(error.status == 0){ $("#editdoctor").modal('hide'); this.logout()}});
                    
                }
                else
                {
                    alert(val.status.reason);
                }
                // console.log(val);
            }, error => { if(error.status == 0){this.logout()}});
        }
        else
        {
            var data = {
                firstName:editPatient.firstName,
                lastName:editPatient.lastName,
                email:editPatient.email,
                address:editPatient.address,
                mobileNumber:editPatient.mobileNumber,
                gardianName:editPatient.gardianName,
                profilePicture: this.new_edit_picture,
                gender:editPatient.genderV,
                gardianNumber:editPatient.gardianNumber,
                nhsnumber:editPatient.nhsnumber
            }

            this.apiService.editPatient(editPatient.id,data).subscribe((data: any) => {
                var status = data.status;
                // console.log(status);
                if(status.status == "SUCCESS"){
                    alert("Patient updated successfully");
                    $("#editdoctor").modal('hide');
                    window.location.reload();
                }
                else{
                    alert(status.reason);
                }
            }, error => { if(error.status == 0){ $("#editdoctor").modal('hide'); this.logout()}});
        }
        
        
    }

    public showPatient(dd) {
        this.apiService.getPatientInfo(dd.id).subscribe((data: any) => {
            data.data.genderV = data.data.gender=="MALE"?1:2;
            this.selected = data.data;
            this.new_edit_picture = data.data.profilePicture;
            $("#viewdoctor").modal('show');
            // console.log(data);
            var status = data.status;
        }, error => { if(error.status == 0){ $("#viewdoctor").modal('hide');this.logout()}});

    }

    public showDelete() {
        this.deleteBox = true;
        this.viewPatient = false;
        this.deleteConfirm = false;
    }
    
    public hideDelete() {
        this.deleteBox = false;
        this.viewPatient = true;
        this.deleteConfirm = false;
    }


    public doDelete() {
        this.deleteConfirm = true;
    }


    public showEditPatient(){
        $("#viewdoctor").modal('hide');
        $("#editdoctor").modal('show');
    }

    public confirmDelete(item) {
        this.apiService.deletePatient(item.id, this.reason).subscribe((data: any) => {
            alert("deleted successfully");
            $("#viewdoctor").modal('hide');
            // console.log(data);
            //this.getPatients();
            this.hideDelete();
            window.location.reload();
            var status = data.status;
        }, error => { if(error.status == 0){$("#viewdoctor").modal('hide'); this.logout()}});
    }

    setOrder(value: string) {
        if (this.order === value) {
          this.reverse = !this.reverse;
        }
    
        this.order = value;
      }

    public changePatientStatus(data) {
        this.apiService.changeUserStatus(data).subscribe((data: any) => {
            const status = data.status_code;
            if (status == 0) {
                this.getPatients();
                Swal.fire({ text: data.message, icon: 'success' });
            } else {
                Swal.fire({text: data.message, icon: 'error'});
            }
        }, error => { if(error.status_code == 1){this.logout()}});
    }

}
